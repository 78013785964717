import { HiArrowNarrowUp, HiDocumentText, HiOutlineUserGroup } from 'react-icons/hi';
import { BsPatchQuestionFill } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { addLastWeekQuestion, addQuestion, addTests, addTotalQuestion, addUser, removeAdminData } from '../../Redux/adminSlice.js';
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Link } from 'react-router-dom';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DashboardCom() {


    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);
    const [totalUsers, setTotalUsers] = useState();
    const [lastWeekUsers, setLastWeekUsers] = useState();
    const [tests, setTests] = useState([]);
    const [totalTests, setTotalTests] = useState();
    const [lastWeekTests, setLastWeekTests] = useState();
    const [questions, setQuestion] = useState([]);
    const [totalQuestion, setTotalQuestion] = useState(0);
    const [lastWeekQuestion, setLastWeekQuestion] = useState(0);
    const [Cq, setCq] = useState(0);
    const [Mcq, setMcq] = useState(0);


    const { currentUser } = useSelector((state) => state.user);


    //---------------------------------- Fetching USER, TESTS & QUESTIONS --------------------------------
    useEffect(() => {
        dispatch(removeAdminData());
        //Fetching Users
        const fetchUsers = async () => {
            try {
                const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/getUsers`, {
                    method: 'GET',
                    headers: { "Content-Type": "application/json" },
                    credentials: "include"
                });
                if (res.ok) {
                    const data = await res.json();
                    dispatch(addUser(data))
                    setUsers(data.users);
                    setTotalUsers(data.totalUsers);
                    setLastWeekUsers(data.lastWeekUsers);
                }
            } catch (err) {
                console.log(err)
            }
        }

        //fetching Tests
        const fetchTests = async () => {
            try {
                const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/test/getAllTests`, {
                    method: 'GET',
                    headers: { "Content-Type": "application/json" },
                    credentials: "include"
                });
                if (res.ok) {
                    const data = await res.json();
                    setTests([...data.tests]);
                    dispatch(addTests(data))
                    setTotalTests(data.totalTests);
                    setLastWeekTests(data.lastWeekTests);
                }
            } catch (err) {
                console.log(err)
            }
        }
        //fetching Questions
        const fetchQuestions = async () => {
            let totalQ = 0
            let lastWeekQ = 0
            try {
                const codingRes = await fetch(`${process.env.REACT_APP_BACKEND}/api/coding/getAllQuestions`, {
                    method: 'GET',
                    headers: { "Content-Type": "application/json" },
                    credentials: "include"
                });
                const data = await codingRes.json();
                if (codingRes.ok) {
                    setQuestion([...questions, ...data.questions]);
                    setCq(data.totalQuestions);
                    totalQ += data.totalQuestions;
                    lastWeekQ += data.lastWeekQuestions;
                }
                const mcqRes = await fetch(`${process.env.REACT_APP_BACKEND}/api/mcq/getAllQuestions`, {
                    method: 'GET',
                    headers: { "Content-Type": "application/json" },
                    credentials: "include"
                });
                if (mcqRes.ok) {
                    const data = await mcqRes.json();
                    setQuestion([...questions, ...data.questions]);
                    setMcq(data.totalQuestions);
                    totalQ += data.totalQuestions;
                    lastWeekQ += data.lastWeekQuestions;
                }
            } catch (err) {
                console.log(err)
            } finally {
                setTotalQuestion(totalQ);
                setLastWeekQuestion(lastWeekQ);
                dispatch(addQuestion(questions));
                dispatch(addTotalQuestion(totalQ));
                dispatch(addLastWeekQuestion(lastWeekQ));
            }
        }
        // calling the functions only if the user is an admin
        if (currentUser.isAdmin) {
            fetchUsers();
            fetchTests();
            fetchQuestions();
        }
    }, [])


    const dataPie1 = {
        labels: ["Coding", "MCQ"],
        datasets: [
            {
                label: "Number of questions",
                data: [Cq, Mcq],
                backgroundColor: ["#3FB8FF", "#5850EC"],
                hoverOffset: 4,
            },
        ],
    };

    const sortedTests = [...tests]
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 3);

    // Sorting users based on createdAt in descending order (most recent first) and taking only the first 5 records
    const sortedUsers = [...users]
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 3);

    return (
        <>
        {!currentUser.isAdmin? (<p className='text-xl font-semibold m-3 text-center text-gray-700'>You are you allowed to view this page's content. Go back.</p>) : (<div className="P-3 mx-auto">
            <div className="flex-wrap flex gap-3 justify-center">
                <div className="flex flex-col gap-y-3">
                    <div className="flex flex-col p-3 dark:bg-slate-800 gap 4 md:w-72 w-full rounded-md shadow-md">
                        <div className="flex justify-between ">
                            <div className="">
                                <h3 className="text-gray-500 text-md uppercase">Total Users</h3>
                                <p className="text-2xl">{totalUsers}</p>
                            </div>
                            <HiOutlineUserGroup className="bg-teal-500 text-white rounded-full text-5xl p-3 shadow-lg" />
                        </div>
                        <div className="flex gap-2 text-sm ">
                            <span className="text-green-500 flex items-center">
                                <HiArrowNarrowUp />
                                {lastWeekUsers}
                            </span>
                            <div className="text-gray-500">Last Week</div>
                        </div>
                    </div>
                    <div className=" bg-white shadow-md rounded-md max-w-72 h-80 object-contain">
                        {/* <ul>
                {sortedUsers.map((user, index) => (
                  <li key={index}>
                    <strong>Name:</strong> {user.name} | 
                    <strong> Email:</strong> {user.email} | 
                
                    <strong> Date created:</strong> {new Date(user.createdAt).toLocaleDateString()}
                  </li>
                ))}
                  
              </ul> */}
                        <div className="flex-col items-center">
                            <h3 className="text-gray-500 text-base font-semibold  uppercase text-center  ">Latest User Details</h3>
                            {sortedUsers.map((user, index) => (

                                <div
                                    key={index}
                                    className="py-2 px-4 w-full  max-w-xs  "
                                >
                                    <div className="px-2 py-1 w-full bg-gradient-to-r from-teal-700 to-cyan-500 rounded-md">
                                        <p className="text-sm font-semibold truncate text-gray-50">{user.name}</p>
                                        <p className="text-[11px] text-gray-200 truncate">{user.email}</p>
                                        <p className="text-[12px] text-gray-300">
                                            Date Created: {new Date(user.createdAt).toLocaleDateString()}
                                        </p>
                                    </div>
                                </div>
                            ))}

                            <Link to='/dashboard?tab=users' className='text-gray-700 text-sm mb-4 ml-4 hover:underline hover:text-teal-700 text-center'>More Details</Link>

                        </div>

                    </div>

                </div>



                <div className="flex flex-col gap-y-3">
                    <div className="flex flex-col p-3 dark:bg-slate-800 gap 4 md:w-72 w-full rounded-md shadow-md">
                        <div className="flex justify-between ">
                            <div className="">
                                <h3 className="text-gray-500 text-md uppercase">Total Tests</h3>
                                <p className="text-2xl">{totalTests}</p>
                            </div>
                            <HiDocumentText className="bg-lime-500 text-white rounded-full text-5xl p-3 shadow-lg" />
                        </div>
                        <div className="flex gap-2 text-sm ">
                            <span className="text-green-500 flex items-center">
                                <HiArrowNarrowUp />
                                {lastWeekTests}
                            </span>
                            <div className="text-gray-500">Last Week</div>
                        </div>
                    </div>

                    {/* <div className=" bg-white shadow-md rounded-md max-w-72  h-80 ">
                        <ul>
                            {sortedTests.map((test, index) => (
                                <li key={index}>
                                    <strong>Test Taken:</strong> {test.testTaken} |
                                    <strong> Language:</strong> {test.language} |
                                    <strong> Level:</strong> {test.level} |

                                    <strong> Marks Obtained:</strong> {test.marksObtained} |
                                    <strong> Date Taken:</strong> {new Date(test.createdAt).toLocaleDateString()}
                                </li>
                            ))}
                        </ul>

                    </div> */}

                    <div className=" bg-white shadow-md rounded-md max-w-72 h-80 object-contain">
                        <div className="flex-col ">
                            <h3 className="text-gray-500 text-base font-semibold  uppercase text-center  ">Latest Test Details</h3>
                            {sortedTests.map((test, index) => (
                                <div
                                    key={index}
                                    className="p-4  w-full  max-w-xs  "
                                >
                                    <div className="px-2 py-1 w-full bg-gradient-to-l from-lime-600 to-green-500 rounded-md">
                                        <p className="text-sm font-semibold truncate text-gray-50">{test.level}</p>
                                        <p className="text-[11px] text-gray-200 truncate">{test.questions.language}</p>
                                        <p className="text-[12px] text-gray-300">
                                            Date Created: {new Date(test.createdAt).toLocaleDateString()}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-y-3">
                    <div className="flex flex-col p-3 dark:bg-slate-800 gap 4 md:w-72 w-full rounded-md shadow-md ">
                        <div className="flex justify-between ">
                            <div className="">
                                <h3 className="text-gray-500 text-md uppercase">
                                    Total Questions
                                </h3>
                                <p className="text-2xl">{totalQuestion}</p>
                            </div>
                            <BsPatchQuestionFill className="bg-indigo-600 text-white rounded-full text-5xl p-3 shadow-lg" />
                        </div>
                        <div className="flex gap-2 text-sm ">
                            <span className="text-green-500 flex items-center">
                                <HiArrowNarrowUp />
                                {lastWeekQuestion}
                            </span>
                            <div className="text-gray-500">Last Week</div>
                        </div>
                    </div>
                    <div className=" bg-white shadow-md rounded-md max-w-72 h-80 ">
                        <p className="text-[11px] text-center mt-2 text-gray-500">All questions are unique and curated by industry experts</p>
                        <Pie data={dataPie1} className="p-4" />
                    </div>
                </div>
            </div>
        </div>)}
        </>
    );
}