import { createSlice } from "@reduxjs/toolkit";
let initialState = {
    users: [],
    noOfUsers: 0,
    noOfUsersWeek: 0,
    questions: [],
    noOfQuestions: 0,
    noOfQuestionsWeek: 0,
    tests: [],
    noOfTests: 0,
    noOfTestsWeek: 0
};
const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        addUser: (state, action) => {
            state.users = [...action.payload.users];
            state.noOfUsers = action.payload.totalUsers;
            state.noOfUsersWeek = action.payload.lastWeekUsers;
        },
        addQuestion: (state, action) => {
            state.questions = [...action.payload];
        },
        addTotalQuestion: (state, action) => {
            state.noOfQuestions = action.payload;
        },
        addLastWeekQuestion: (state, action) => {
            state.noOfQuestionsWeek = action.payload;
        },
        addTests: (state, action) => {
            state.tests = [...action.payload.tests];
            state.noOfTests = action.payload.totalTests;
            state.noOfTestsWeek = action.payload.lastWeekTests;
        },
        removeAdminData: (state) => {
                state.users = [];
                state.noOfUsers = 0;
                state.noOfUsersWeek = 0;
                state.questions = [];
                state.noOfQuestions = 0;
                state.noOfQuestionsWeek = 0;
                state.tests = [];
                state.noOfTests = 0;
                state.noOfTestsWeek = 0;
        },
    },
});
export const {
    addUser,
    addQuestion,
    addTotalQuestion,
    addLastWeekQuestion,
    addTests,
    removeAdminData
} = adminSlice.actions;
export const adminReducer = adminSlice.reducer;