
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';




export default function ShowCandidateProfile() {

    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const { currentUser } = useSelector((state) => state.user);
    const [profileCompletion, setProfileCompletion] = useState(0); // Store profile completion percentage
    
    const navigate = useNavigate(); // Initialize navigate
    const { candidate } = location.state || {}; // Ac


    const handleNavigate = () => {
        navigate('/dashboard?tab=search'); // This will navigate to /User
    };

 
    
    useEffect(() => {
        
        setLoading(false);
    }, []); 

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="profile-page p-6 bg-gradient-to-b from-blue-50 via-white to-blue-50 min-h-screen">
            {/* Header */}
            <div className="header flex items-center space-x-4 bg-gradient-to-r from-blue-500 to-blue-700 text-white p-6 rounded-lg shadow-lg">
                <img
                    src={candidate.profilePicture}
                    alt="Profile"
                    className="profile-picture rounded-full border-4 border-white w-24 h-24 object-cover"
                />
                <div className="profile-info flex-grow">
                    <h2 className="text-2xl font-bold">{candidate.name}</h2>
                    <p className="text-md font-light">{candidate.userInfo?.bio}</p>
                </div>
                <div className="metrics flex space-x-8">
                    <div className="text-center">
                        <div className="text-2xl font-semibold">{42}</div>
                        <div className="text-sm font-light">Number of Tests Taken</div>
                    </div>
                    <div className="text-center">
                        <div className="text-2xl font-semibold">{11}</div>
                        <div className="text-sm font-light">Recruiter Actions</div>
                    </div>
                </div>
            </div>
            
            {/* Basic Details */}
            <div className="basic-details mt-6 p-6 bg-white rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold text-gray-800">Basic Details</h3>
                <div className="mt-4 space-y-2 text-gray-600">
                    <p><strong>Email:</strong> {candidate.email}</p>
                    <p><strong>Bio:</strong> {candidate.userInfo?.bio}</p>
                    <p><strong>Age:</strong> {candidate.userInfo?.age}</p>
                    <p><strong>Location:</strong> {candidate.userInfo?.location}</p>
                    <p><strong>Visa Status:</strong> {candidate.userInfo?.visaStatus}</p>
                </div>
            </div>
            {/* Career Preferences */}
            <div className="career-preferences mt-6 p-6 bg-white rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold text-gray-800">Your Career Preferences</h3>
                <div className="mt-4 space-y-2 text-gray-600">
                    <p><strong>Job Type:</strong> {candidate.majorSKill || 'Not specified'}</p>
                    <p><strong>Willing to Relocate:</strong> {candidate.userInfo?.relocate ? 'Yes' : 'No'}</p>
                    <p><strong>Preferred Locations:</strong>
                        <ul>
                            {candidate.userInfo?.relocateLocations.map((location, index) => (
                                <li key={index}>{location}</li>
                            ))}
                        </ul>
                    </p>
                </div>
            </div>
            {/* Education */}
            <div className="basic-details mt-6 p-6 bg-white rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold text-gray-800">Recent Education</h3>
                <div className="mt-4 space-y-2 text-gray-600">
                    <p><strong>College Name:</strong> {candidate.userInfo?.mostRecentDegree?.collageName || 'Not specified'}</p>
                    <p><strong>Degree:</strong> {candidate.userInfo?.mostRecentDegree?.degree || 'Not specified'}</p>
                    <p><strong>Start Year:</strong> 
                      {candidate.userInfo?.mostRecentDegree?.startYear 
                        ? new Date(candidate.userInfo?.mostRecentDegree?.startYear).getFullYear()
                        : 'Not specified'}
                    </p>
                    <p><strong>End Year:</strong> 
                      {candidate.userInfo?.mostRecentDegree?.endYear 
                        ? new Date(candidate.userInfo?.mostRecentDegree?.endYear).getFullYear()
                        : 'Not specified'}
                    </p>
                </div>
            </div>
            {/* Experience */}
            <div className="mt-6 p-6 bg-white rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold text-gray-800">Experience</h3>
                <div className="mt-4 space-y-2 text-gray-600">
                    {candidate.userInfo?.experience?.length > 0 ? (
                        candidate.userInfo.experience.map((exp, index) => (
                            <p key={index}>
                                <strong>{exp.domain}:</strong> {exp.yearsOfExperience} year(s)
                            </p>
                        ))
                    ) : (
                        <p>No experience listed.</p>
                    )}
                </div>
            </div>
            {/* Skills */}
            <div className="mt-6 p-6 bg-white rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold text-gray-800">Skills</h3>
                <div className="mt-4 space-y-2 text-gray-600">
                    {candidate.userInfo?.skills.length > 0 ? (
                        <ul>
                            {candidate.userInfo.skills.map((skill, index) => (
                                <li key={index}>{skill}</li>
                            ))}
                        </ul>
                    ) : (
                        <p>No skills listed.</p>
                    )}
                </div>
            </div>
            {/* Courses */}
            <div className="mt-6 p-6 bg-white rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold text-gray-800">Courses</h3>
                <div className="mt-4 space-y-2 text-gray-600">
                    {candidate.userInfo?.courses.length > 0 ? (
                        <ul>
                            {candidate.userInfo.courses.map((course, index) => (
                                <li key={index}>{course}</li>
                            ))}
                        </ul>
                    ) : (
                        <p>No courses listed.</p>
                    )}
                </div>
            </div>
            {/* Back Button */}
            <button onClick={handleNavigate} className="mt-6 p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
                Back to Candidates List
            </button>
        </div>
    );
};

