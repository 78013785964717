import React, { useState, useEffect } from "react";
import { Button, Table, TextInput } from "flowbite-react";
import { AiOutlineSearch } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import { updateAllViewedProfiles, updateRecruiterInfo } from "../../Redux/recruiterSlice";

export default function ViewedCandidates() {

    const { currentUser } = useSelector((state) => state.user);
    const { viewedProfiles, currentRecruiter     } = useSelector((state) => state.recruiter);

    const [query, setQuery] = useState("");
    const [displayedUsers, setDisplayedUsers] = useState([]);
    const [showMore, setShowMore] = useState(true);
    const [startIndex, setStartIndex] = useState(0);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const usersPerPage = 9;

    const fetchRecruiterInfo = async () => {
        if (currentUser && currentRecruiter) {
            try {
                const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/recruiter/getRecruiter/${currentRecruiter._id}`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include',
                });

                const data = await res.json();
                if (res.ok) {
                    dispatch(updateRecruiterInfo(data.user));
                    dispatch(updateAllViewedProfiles(data.viewedProfiles));
                } else {
                    console.error("Failed to fetch recruiter:", data.message);
                }
            } catch (err) {
                console.error("Error fetching recruiter info:", err);
            }
        }
    };


    useEffect(() => {
        if (currentUser && currentRecruiter) {
            fetchRecruiterInfo();
        }
    }, []);



    useEffect(() => {
        // Initialize the displayedUsers with the first batch of profiles in reverse order
        const initialUsers = viewedProfiles.slice(-usersPerPage).reverse();
        setDisplayedUsers(initialUsers);
        setStartIndex(usersPerPage);
        setShowMore(viewedProfiles.length > usersPerPage);
    }, [viewedProfiles]);

    const handleShowMore = () => {
        const reversedProfiles = [...viewedProfiles].reverse();

        // Slice the next batch of users based on the startIndex
        const nextUsers = reversedProfiles.slice(startIndex, startIndex + usersPerPage);

        if (nextUsers.length > 0) {
            setDisplayedUsers((prev) => [...prev, ...nextUsers]);
            setStartIndex((prev) => prev + usersPerPage);

            // Disable "Show More" if we've loaded all users
            if (startIndex + usersPerPage >= reversedProfiles.length) {
                setShowMore(false);
            }
        }
    };


    const handleViewProfile = async (candidate) => {
        navigate(`/filter-candidate/profile`, { state: { candidate } });
    }

    const handleSearch = () => {
        const lowerCaseQuery = query.toLowerCase();

        if (query.length >= 3) {
            const filteredUsers = viewedProfiles
                .filter((user) =>
                    user.name.toLowerCase().includes(lowerCaseQuery) ||
                    user.email.toLowerCase().includes(lowerCaseQuery) ||
                    user.majorSKill?.toLowerCase().includes(lowerCaseQuery)
                )
                .reverse();

            setDisplayedUsers(filteredUsers.slice(0, usersPerPage));
            setShowMore(filteredUsers.length > usersPerPage);
            setStartIndex(usersPerPage); // Reset the startIndex for search results
        } else if (query === "") {
            setDisplayedUsers(viewedProfiles.slice(-usersPerPage).reverse());
            setShowMore(viewedProfiles.length > usersPerPage);
            setStartIndex(usersPerPage); // Reset the startIndex for the original list
        }
    };

    useEffect(() => {
        handleSearch();
    }, [query]);

    return (
        <div className="p-3 mx-auto">
            <div className="w-full flex-col justify-center items-center">
                <div className="mx-auto w-3/4">
                    <h2 className="text-lg text-gray-700 pl-3 pb-2">Search user by email or name:</h2>
                    <form>
                        <TextInput
                            type="text"
                            placeholder="Search..."
                            rightIcon={AiOutlineSearch}
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                        />
                    </form>
                </div>

                <div className="mt-4">
                    {displayedUsers.length === 0 && query.length >= 3 && (
                        <p>No users found.</p>
                    )}
                </div>

                <div className='h-screen table-auto overflow-x-scroll md:mx-auto p-3'>
                    {currentUser.isRecruiter && displayedUsers.length > 0 ? (
                        <>
                            <Table hoverable className="shadow-md mt-3">
                                <Table.Head>
                                    <Table.HeadCell>User image</Table.HeadCell>
                                    <Table.HeadCell>Name</Table.HeadCell>
                                    <Table.HeadCell>Email</Table.HeadCell>
                                    <Table.HeadCell>Major Skill</Table.HeadCell>
                                    <Table.HeadCell>View Profile</Table.HeadCell>
                                </Table.Head>
                                {displayedUsers.map((user) => (
                                    <Table.Body className="divide-y" key={user._id}>
                                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                            <Table.Cell>
                                                <img src={user.profilePicture} alt={user.name} className="w-10 h-10 object-cover rounded-full" />
                                            </Table.Cell>
                                            <Table.Cell className="text-sm text-gray-900 dark:text-gray-300">
                                                <Link>{user.name}</Link>
                                            </Table.Cell>
                                            <Table.Cell className="text-xs text-gray-900 dark:text-gray-300">
                                                {user.email}
                                            </Table.Cell>
                                            <Table.Cell className="text-sm text-gray-900 dark:text-gray-300">
                                                {user.majorSKill && user.majorSKill.trim() !== "" ? user.majorSKill : 'Unknown'}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <div className="bg-sky-600 rounded-full hover:bg-sky-800 flex justify-center"
                                                    onClick={() => handleViewProfile(user)}>
                                                    <FaArrowRight className="p-3 text-white" size={40} />
                                                </div>
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                ))}
                            </Table>
                        </>
                    ) : (
                        <p className="text-center">No users to show!</p>
                    )}
                </div>

                {showMore && (
                    <Button onClick={handleShowMore} className="mx-auto my-3">
                        Show more
                    </Button>
                )}
            </div>
        </div>
    );
}