import 'animate.css';

export default function ComingSoon() {
    return (
        <div className='h-fit w-fit sticky'>
            <video className="h-full w-full sticky" autoplay="true" muted="true" loop="true">
                <source src="comingsoon.mp4" type="video/mp4" />
            </video>

            <div className='absolute flex inset-0 justify-center items-center z-20'><p className='text-blue-600 animate__animated animate__lightSpeedInRight   max-w-lg text-3xl font-semibold leading-loose dark:text-white mb-24'>
                Content coming soon......</p>
            </div>

        </div>
    )
};