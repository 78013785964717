import { Card } from "flowbite-react";
import { BsBoxArrowInDownRight } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateRecruiterInfo } from '../Redux/recruiterSlice.js'
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';


export default function ViewPlans() {


    const { currentRecruiter } = useSelector((state) => state.recruiter);
    const { currentUser } = useSelector((state) => state.user);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    function getDateInIST(date) {
        // Convert the time to IST (UTC + 5:30) and strip the time part
        const utcOffset = 5.5 * 60 * 60 * 1000; // IST is UTC + 5:30
        let istDate = new Date(date.getTime() + utcOffset);
        return new Date(istDate.getFullYear(), istDate.getMonth(), istDate.getDate()); // Only return date (no time)
    }

    // Get current server date in IST
    let currentISTDate = getDateInIST(new Date(Date.now()));

    // Add 1 month to the current date for expiry
    function getExpiryDate(purchasedDate) {
        let expiryDate = new Date(purchasedDate);
        expiryDate.setMonth(expiryDate.getMonth() + 1);
        return getDateInIST(expiryDate); // Ensure we return only the date
    }


    const plans = {
        basic: {
            planType: 'basic',
            price: 99,
            monthlyViews: 50,
            freeResumeView: true,
            purchasedOn: currentISTDate,
            expiredOn: getExpiryDate(currentISTDate),
            resumesAfterLimit: 0,
            totalViewsUsed: 0,
            resumesViewed: [],
            isActive: currentISTDate < getExpiryDate(currentISTDate),
            highlights: ['If view limit exceeded pay $2.99 per resume.']
        },
        pro: {
            planType: 'pro',
            price: 299,
            monthlyViews: 100,
            freeResumeView: true,
            purchasedOn: currentISTDate,
            expiredOn: getExpiryDate(currentISTDate),
            resumesAfterLimit: 0,
            totalViewsUsed: 0,
            resumesViewed: [],
            isActive: currentISTDate < getExpiryDate(currentISTDate),
            highlights: ['If view limit exceeded pay $2.99 per resume.', 'Skill Test Result visible.']
        },
        elite: {
            planType: 'elite',
            price: 499,
            monthlyViews: 240,
            freeResumeView: true,
            purchasedOn: currentISTDate,
            expiredOn: getExpiryDate(currentISTDate),
            resumesAfterLimit: 0,
            totalViewsUsed: 0,
            resumesViewed: [],
            customSkillTest: 10,
            isActive: currentISTDate < getExpiryDate(currentISTDate),
            highlights: ['If view limit exceeded pay $2.99 per resume.', 'Skill Test Result visible.', 'Send 10 skill assessments to candidates.']
        }
    };

    const handlePlanSelection = async (planName) => {
        let body = {}
        if (planName === 'Basic') {
            body = plans.basic
        }
        else if (planName === 'Pro') {
            body = plans.pro

        }
        else if (planName === 'Elite') {
            body = plans.elite

        }

        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/recruiter/updateplan/${currentRecruiter?._id}`, {
                method: "POST",
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(body)
            });

            if (res.ok) {
                const data = await res.json();
                dispatch(updateRecruiterInfo(data.recruiterInfo));
                navigate('/dashboard?tab=recruiterProfile'); // Redirect after animation

            }
        } catch (error) {
            console.log(error);
        }

    }






    const initialPlanValue = [
        {
            name: "Basic",
            price: 99,
            monthlyProfileViews: 50,
            customSkillTest: 0,
            highlights: {
                h1: "If view limit exceeded pay $2.99 per resume.",
            }
        },
        {
            name: "Pro",
            price: 299,
            monthlyProfileViews: 100,
            customSkillTest: 0,
            highlights: {
                h1: "If view limit exceeded pay $2.99 per resume.",
                h2: "Skill Test Result visible.",
            }
        },
        {
            name: "Elite",
            price: 499,
            monthlyProfileViews: 240,
            customSkillTest: 10,
            highlights: {
                h1: "If view limit exceeded pay $2.99 per resume.",
                h2: "Skill Test Result visible.",
                h3: "Send 10 skill assessments to candidates."
            }
        }
    ];

    // Free resume highlight for every plan
    const freeResumeHighlight = "View 1 resume for free.";

    return (
        <>
        <Helmet>
        <title>H-or-G: Skills Assessment & Candidate Screening with Flexible Pricing - Sign Up Free</title>          
        <meta name="description" content="Discover HorG’s flexible pricing for skills assessments and candidate screening. Start with a free skill test to find top talent. Transparent pricing, no hidden fees." />
        <meta name="keywords" content="skill assessment tools, HorG skill assessment solutions, online skill measurement, skill test platform, affordable skill pricing, online skills testing, candidate skill evaluation, accurate skill measurement, horg pricing for skill tests, skill assessment for hiring" />           
            </Helmet>
            {currentRecruiter && currentRecruiter?.currentPlan?.planType !== 'free' ? (
                <div className="mx-4">
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1, ease: "easeOut" }}
                        whileHover={{ scale: 0.75 }}
                        className="min-w-96 max-w-[1200px] m-6 mx-auto p-8 bg-gradient-to-r from-purple-600 via-pink-500 to-red-500 rounded-3xl shadow-2xl relative overflow-hidden transform transition-all duration-700 ease-out"
                    >
                        {/* Decorative Circles */}
                        <motion.div
                            className="absolute -top-20 -right-20 bg-white bg-opacity-10 w-64 h-64 rounded-full"
                            animate={{ rotate: 360 }}
                            transition={{ repeat: Infinity, duration: 20, ease: "linear" }}
                        ></motion.div>
                        <motion.div
                            className="absolute -bottom-24 -left-24 bg-white bg-opacity-20 w-96 h-96 rounded-full"
                            animate={{ rotate: -360 }}
                            transition={{ repeat: Infinity, duration: 30, ease: "linear" }}
                        ></motion.div>
                        {/* Plan Details */}
                        <div className="text-center space-y-6">
                            <motion.h2
                                className="text-4xl font-extrabold text-white tracking-wider uppercase"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 0.5, duration: 0.8 }}
                            >
                                {currentRecruiter.currentPlan.planType}
                            </motion.h2>

                            <motion.p
                                className="text-lg font-medium text-white opacity-90"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 0.7, duration: 0.8 }}
                            >
                                Views Left: {currentRecruiter.currentPlan.monthlyViews}
                            </motion.p>
                            <motion.p
                                className="text-lg font-medium text-white opacity-80"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 0.9, duration: 0.8 }}
                            >
                                Expires On: {new Date(currentRecruiter.currentPlan.expiredOn).toLocaleDateString()}
                            </motion.p>
                        </div>
                        {/* Renew Button */}
                        <div className="mt-8 flex justify-center">
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                className="px-8 py-3 bg-white text-purple-700 font-bold text-xl rounded-full shadow-lg hover:bg-purple-700 hover:text-white hover:shadow-2xl transition-all duration-500 ease-in-out"
                            >
                                Renew Plan
                            </motion.button>
                        </div>
                    </motion.div>
                </div>

            ) : (
                <div className="w-screen h-auto flex justify-center">
                    <div className="width-container">
                        <div className="flex flex-col items-center">

                            <div className="my-14 w-3/4 mx-auto">
                                <h1 className="font-semibold text-5xl text-gray-700 text-center">Choose a plan</h1>
                                <p className="text-center text-gray-500 text-sm mt-2">Choose a plan that suits your hiring needs and access powerful features to find the right candidates.</p>
                            </div>

                            <div className="flex flex-row justify-center items-center gap-3 my-3">
                                <p className="text-3xl text-gray-600">Let's get started</p>
                                <BsBoxArrowInDownRight size={30} />
                            </div>

                            <div className="w-full h-auto flex flex-col items-center md:flex-row md:justify-center gap-4 mt-10">
                                {initialPlanValue.map((plan, index) => (
                                    <Card key={index} className="max-w-sm md:w-80 h-[550px] flex flex-col justify-between">
                                        <div className="flex flex-col justify-between h-full">
                                            <div className="text-center">
                                                <h5 className="mb-4 text-3xl text-gray-500 font-semibold">{plan.name}</h5>
                                                <div className="flex items-baseline justify-center text-gray-900 dark:text-white">
                                                    <span className="text-3xl font-semibold text-sky-600">$</span>
                                                    <span className="text-5xl font-extrabold tracking-tight text-sky-500">{plan.price}</span>
                                                    <span className="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400">/month</span>
                                                </div>
                                            </div>
                                            <ul className="my-5 space-y-3 h-[250px] flex flex-col justify-center">
                                                <li className="flex space-x-3">
                                                    <FaCheckCircle className="h-4 w-4 text-cyan-600" />
                                                    <span className="text-sm font-normal leading-tight text-gray-500">
                                                        {plan.monthlyProfileViews} profile views per month
                                                    </span>
                                                </li>
                                                <li className="flex space-x-3">
                                                    <FaCheckCircle className="h-4 w-4 text-cyan-600" />
                                                    <span className="text-sm font-normal leading-tight text-gray-500">
                                                        {plan.customSkillTest} skill assessments
                                                    </span>
                                                </li>

                                                {/* Common highlight for every plan */}
                                                <li className="flex space-x-3">
                                                    <FaCheckCircle className="h-4 w-4 text-cyan-600" />
                                                    <span className="text-sm font-normal leading-tight text-gray-500">
                                                        {freeResumeHighlight}
                                                    </span>
                                                </li>

                                                {/* Map through the unique highlights for each plan */}
                                                {Object.keys(plan.highlights).map((key, idx) => (
                                                    <li key={idx} className="flex space-x-3">
                                                        <FaCheckCircle className="h-4 w-4 text-cyan-600" />
                                                        <span className="text-sm font-normal leading-tight text-gray-500">
                                                            {plan.highlights[key]}
                                                        </span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                        {/* Button aligned at the bottom */}
                                        <div className="mt-auto">
                                            <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-lg bg-cyan-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-4 focus:ring-cyan-200 dark:focus:ring-cyan-900"
                                                onClick={() => handlePlanSelection(plan.name)} >
                                                Choose plan
                                            </button>
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}