import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    currentUser: null,
    error: null,
    loading: false
}
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        signInStart: (state) => {
            state.currentUser = null;
            state.loading = true;
            state.error = null;
        },
        signInSuccess: (state, action) => {
            state.currentUser = action.payload;
            state.loading = false;
            state.error = null;
        },
        signInFailure: (state, action) => {
            state.currentUser = null;
            state.loading = false;
            state.error = action.payload;
        },
        userSignOut: (state) => {
            state.currentUser = null;
            state.loading = false;
            state.error = null;
        },
        updateCurrentUser: (state, action) => {
            state.currentUser = action.payload;
            state.error = null;
            state.loading = false;
        },
        removeErrorAndLoad: (state) => {
            state.error = null;
            state.loading = false;
        }
    }
});
export const {
    signInStart,
    signInSuccess,
    signInFailure,
    userSignOut,
    updateCurrentUser,
    removeErrorAndLoad
} = userSlice.actions;
export const userReducer = userSlice.reducer;