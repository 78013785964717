import axios from 'axios';
import React, { useEffect } from "react";
import Lottie from "lottie-react";
import trophy from "../assets/trophy.json";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Page, Text, View, Document, StyleSheet, Image, PDFDownloadLink, pdf, Svg, Rect, Path, Circle, Font } from '@react-pdf/renderer';
import PDF_viewer from './PDF_viewer.jsx';
import RobotoRegulari from '../assets/fonts/Roboto-Light.ttf';
import { isPDFSentSuccess } from '../Redux/skillTest/skillTestSlice.js';



//-----------------------Styles for the pdf-------------------------------------------------------------
// Register the font
Font.register({
  family: 'Roboto',
  src: RobotoRegulari,
});
const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: '#F4F8FB',
    fontSize: 12,
    color: '#01579B',
    width: '100%',
    height: '100%',
    fontFamily: 'Roboto', // Apply the Roboto font
  },
  header: {
    backgroundColor: '#01579B',
    color: '#E1F5FE',
    padding: 15,
    textAlign: 'center',
    borderBottom: '5px solid #0288D1',
    marginBottom: 20,
  },
  header1Icon: {
    width: 24,
    height: 24,
    marginRight: 10,
  },
  header1: {
    fontSize: 20,
    color: '#000000',
    padding: 15,
    textAlign: 'center',
    marginBottom: 20,
  },
  logo: {
    width: 120,
    marginBottom: 10,
    alignSelf: 'center',
  },
  candidateInfo: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    backgroundColor: '#E3F2FD',
    borderRadius: 10,
  },
  infoText: {
    fontSize: 14,
    color: '#01579B',
  },
  resultStatus: {
    marginTop: 20,
    fontSize: 22,
    color: '#FF5252',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  resultStatusPass: {
    marginTop: 20,
    fontSize: 22,
    color: '#008000',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  percentageText: {
    marginTop: 10,
    fontSize: 20,
    color: '#01579B',
    textAlign: 'center',
  },
  section: {
    marginTop: 20,
    padding: 15,
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  },
  breakdownSection: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#E3F2FD',
    padding: 15,
    borderRadius: 10,
  },
  breakdownRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
  },
  breakdownText: {
    fontSize: 12,
    color: '#000000',
    width: '20%',
  },
  breakdownBarContainer: {
    width: '70%',
    backgroundColor: '#ECEFF1',
    height: 12,
    borderRadius: 6,
    overflow: 'hidden',
  },
  breakdownBar: {
    height: 12,
    borderRadius: 6,
    backgroundColor: '#0288D1',
  },
  // Adjusted for marks and pie chart placement
  scoreAndChartContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 30,
    padding: 20,
    backgroundColor: '#FFF3E0',
    borderRadius: 10,
  },
  scoreSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '60%', // Ensure the marks section occupies a larger area
  },
  scoreText: {
    fontSize: 18,
    color: '#0288D1',
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  pieChart: {
    width: 120,
    height: 120,
    borderRadius: '50%',
  },
  questionSection: {
    marginTop: 30,
    padding: 20,
    backgroundColor: '#FFF3E0',
    borderRadius: 10,
    borderTop: '2px solid #0288D1',
  },
  footerText: {
    textAlign: 'center',
    marginTop: 20,
    fontSize: 10,
    color: '#0288D1',
  },
  pieChart: {
    position: 'relative', // Important for text overlay
    width: 100,
    height: 100,
    justifyContent: 'center',
    alignItems: 'center', // Centers the text horizontally
  },
  textContainer: {
    position: 'absolute', // Allows positioning over the circle
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center', // Centers text vertically
    alignItems: 'center', // Centers text horizontally
  },
  pieChartText: {
    fontSize: 18,
    color: '#000',
    fontWeight: 'bold',
  },
});



export default function InitialResult() {


  const { currentUser } = useSelector((state) => state.user);
  const { marksObtained, questions, answers, loading, timeTaken, mcqRightAnswerCount, codingRightAnswerCount, isPDFSent, error } = useSelector((state) => state.skillTest);
  const { preTest } = useSelector((state) => state.preTest);

  const dispatch = useDispatch();

  const percentage = Math.round((marksObtained / preTest.fullMarks) * 100);
  const [countdown, setCountdown] = useState(10);
  let redirectUrl = '';
  let message = '';

  // Determine the redirect URL and message based on preTest level and percentage
  if (preTest.level === 'easy') {
    if (preTest.percentage > 80) {
      redirectUrl = 'https://bendainfotech.com/profile-marketing/';
      message = `Congratulations ${currentUser.name}, you scored really well!`;
    } else {
      redirectUrl = 'https://bendainfotech.com/job-assistance/';
      message = `${currentUser.name}, you could improve!`;
    }
  } else if (preTest.level === 'medium') {
    if (preTest.percentage > 70) {
      redirectUrl = 'https://bendainfotech.com/profile-marketing/';
      message = `Congratulations ${currentUser.name}, you did great!`;
    } else {
      redirectUrl = 'https://bendainfotech.com/job-assistance/';
      message = `${currentUser.name}, try to work harder next time!`;
    }
  } else if (preTest.level === 'hard') {
    if (preTest.percentage > 60) {
      redirectUrl = 'https://bendainfotech.com/profile-marketing/';
      message = `Well done ${currentUser.name}, you passed the hard test!`;
    } else {
      redirectUrl = 'https://bendainfotech.com/job-assistance/';
      message = `${currentUser.name}, you can improve, don't give up!`;
    }
  }

useEffect(() => {
  let timer;
  // Start the countdown if there's a valid redirect URL
  if (redirectUrl) {
    timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);
  }

  // Redirect to the determined URL when the countdown reaches 0
  if (countdown === 0 && redirectUrl) {
    window.open(redirectUrl, '_blank');
    clearInterval(timer);
  }

  return () => clearInterval(timer); // Clear the interval on component unmount
}, [countdown, redirectUrl]);
const handleRedirect = () => {
  window.open(redirectUrl, '_blank');
};





  //-----------------------------------------Creating my document----------------------------------------------
  const MyDocument = () => {

    const safeDivision = (numerator, denominator) => {
      return denominator > 0 ? numerator / denominator : 0;
    };

    const subjects = [
      { name: 'Coding', no: preTest.numOfCoding, correct: codingRightAnswerCount },
      { name: 'Multiple Choice Questions', no: preTest.numOfMCQ, correct: mcqRightAnswerCount },
    ];
    const formattedDate = new Date().toLocaleDateString();
    const percentage = preTest.fullMarks > 0 ? ((
      marksObtained / preTest.fullMarks) * 100).toFixed(2) : 0;
    const pieChartValue = preTest.fullMarks > 0 ? Math.min((marksObtained / preTest.fullMarks) * 282, 282) : 0;


    return (
      <Document>
        <Page size="A4" style={styles.page}>
          {/* Header */}
          <View style={styles.header}>
            <Image style={styles.logo} src="benda-infotech.png" />
            <Text style={styles.reportTitle}>Evaluation Report</Text>
            <Text>{formattedDate}</Text>
          </View>
          <View>
            <Text style={styles.header1}>Skill Assessement Test for {preTest.language === 'dataanalyst' ? 'Data Analyst' : preTest.language === 'java' ? "Java" : preTest.language === 'businessAnalyst' ? 'Business Analyst' : preTest.language === 'businessIntelligence' ? 'Business-Intelligence' : preTest.language === 'financialAnalyst' ? 'Financial Analyst' : (
              <>
                <Text>{`${preTest.language}`}</Text>
              </>
            )} ({preTest.level})</Text>
          </View>
          {/* Candidate Information */}
          <View style={styles.candidateInfo}>
            <Text style={styles.infoText}>Name: {currentUser.name}</Text>
            <Text style={styles.infoText}>Email: {currentUser.email}</Text>
            {/* <Text style={styles.infoText}>Exam: {exam}</Text>
                    <Text style={styles.infoText}>Level: {level}</Text> */}
          </View>
          {/* Result Status */}
          <Text style={marksObtained / preTest.fullMarks < 0.5 ? styles.resultStatus : styles.resultStatusPass}>{marksObtained / preTest.fullMarks < 0.5 ? 'FAIL' : 'PASS'}</Text>
          <Text style={styles.percentageText}>Total: {percentage}%</Text>
          {/* Breakdown Section */}
          <View style={styles.breakdownSection}>
            {subjects.map((subject, index) => (
              <View key={index} style={styles.breakdownRow}>
                {subject.no > 0 && <Text style={styles.breakdownText}>{subject.name}</Text>}
                {subject.no > 0 && <View style={styles.breakdownBarContainer}>
                  {subject.no > 0 && <View
                    style={[
                      styles.breakdownBar,
                      { width: `${safeDivision(subject.correct, subject.no) * 100}%`, backgroundColor: index % 2 === 0 ? '#1E40AF' : '#0288D1' },
                    ]}
                  />}
                </View>}
                {subject.no > 0 && <Text style={styles.breakdownText}>{subject.correct}/{subject.no}</Text>}
              </View>
            ))}
          </View>
          {/* Pie Chart and Score */}
          <View style={styles.scoreAndChartContainer}>
            {/* Scores Section */}
            <View style={styles.scoreSection}>
              <Text style={styles.scoreText}>Obtained marks: {marksObtained}</Text>
              <Text style={styles.scoreText}>Total marks: {preTest.fullMarks}</Text>
            </View>
            {/* Pie Chart Section */}
            {/* <View style={styles.pieChart}>
              <Svg >
                <Circle cx="50" cy="50" r="45" stroke="#D1D5DB" strokeWidth="10" fill="none" />
                <Circle
                  cx="50"
                  cy="50"
                  r="45"
                  stroke={marksObtained / preTest.fullMarks > 0.5 ? '#1E40AF' : '#D32F2F'}
                  strokeWidth="10"
                  strokeDasharray={`${pieChartValue}, 282`}
                  fill="none"
                  strokeLinecap="round"
                />
              </Svg>
            </View>
          </View> */}
          <View style={styles.pieChart}>
  <Svg height="100" width="100">
    {/* Background Circle */}
    <Circle cx="50" cy="50" r="45" stroke="#D1D5DB" strokeWidth="10" fill="none" />

    {/* Foreground Circle for dynamic progress */}
    <Circle
      cx="50"
      cy="50"
      r="45"
      stroke={ marksObtained/preTest.fullMarks > 0.5 ? '#1E40AF' : '#D32F2F'}
      strokeWidth="10"
      strokeDasharray={`${pieChartValue}, 282`}
      fill="none"
      strokeLinecap="round"
    />
  </Svg>

  {/* Text in the middle */}
  <View style={styles.textContainer}>
    <Text style={styles.pieChartText}>
      {`${Math.round((marksObtained / preTest.fullMarks ) * 100)}%`}
    </Text>
  </View>
</View>
                </View>
          {/* Time Section */}
          <View style={styles.breakdownSection}>
            <View style={styles.breakdownRow}>
              <Text style={styles.breakdownText}>Total Time:</Text>
              <Text style={styles.breakdownText}>{preTest.time} mins</Text>
            </View>
            <View style={styles.breakdownRow}>
              <Text style={styles.breakdownText}>Time Taken:</Text>
              <Text style={styles.breakdownText}>{timeTaken} mins</Text>
            </View>
          </View>
          {/* Footer */}
          <Text style={styles.footerText}>© BendaInfotech.com all rights reserved.</Text>
        </Page>
      </Document>
    )
  }


  // Function to generate PDF and send it to the backend
const sendPDFToBackend = async () => {
  try {
    // Generate the PDF as a blob
    const pdfBlob = await pdf(<MyDocument />).toBlob();

    // Convert the Blob to Base64
    const reader = new FileReader();
    reader.readAsDataURL(pdfBlob);
    reader.onloadend = async () => {
      const base64data = reader.result;

      // Send the Base64 PDF to the candidate
      await axios.post(`${process.env.REACT_APP_BACKEND}/api/nodemailer/sendTestResult`,
        {
          pdf: base64data,
          name: currentUser.name,
          email: currentUser.email,
          language: preTest.language,
          level: preTest.level
        },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );

      // Send the result to the office
      await axios.post(`${process.env.REACT_APP_BACKEND}/api/nodemailer/sendPDFToOffice`,
        {
          pdf: base64data,
          name: currentUser.name,
          email: currentUser.email,
          language: preTest.language,
          level: preTest.level
        },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );

      // Set the flag in localStorage after both API calls succeed
      dispatch(isPDFSentSuccess());
    };
  } catch (error) {
    console.error('Error sending PDF', error);
  }
};


  // Effect to check if the PDF has already been sent and send if not
  useEffect(() => {
    if (isPDFSent !== true) {
      sendPDFToBackend();
    }

  }, []);



  //--------------- Passing data to the PDF viewer for download-----------------
  const openPdfInNewTab = async () => {
    const blob = await pdf(<PDF_viewer name={currentUser.name}
      email={currentUser.email}
      exam={preTest.language}
      level={preTest.level}
      no_of_mcq={preTest.numOfMCQ}
      no_of_coding={preTest.numOfCoding}
      totalMarksobtained={marksObtained}
      totalMarks={preTest.fullMarks}
      time_taken={timeTaken}
      total_time={preTest.time}
      mcqAnswerCount={mcqRightAnswerCount}
      codingAnswerCount={codingRightAnswerCount} />).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  };


  return (
    <div className="m-5 flex flex-col justify-center items-center">
      <div className="max-w-lg flex flex-col items-center h-screen mx-auto lg:h-auto bg-blue-500 border border-gray-200 rounded-lg shadow">
        <div className="w-[240px]">
          <Lottie animationData={trophy} />
        </div>
        <div className="p-5 flex flex-col justify-center items-center">
      <h5 className="mb-2 text-3xl font-bold text-center tracking-tight text-white">
        {message}
      </h5>
      <p className="mb-3 text-2xl font-normal text-center text-gray-200 dark:text-yellow-300">
        YOU SCORED <b>{marksObtained}</b>
      </p>
     {countdown!==0 && <p className="text-center text-gray-200">Redirecting in {countdown} seconds...</p>}
     <div>
     <button
        onClick={handleRedirect}
        className="mt-5 px-4 py-2 bg-green-500 text-white font-bold rounded hover:bg-green-600 focus:outline-none"
      >
     Continue
      </button>
      </div>
    </div>
      </div>
      <button className="text-white mt-4  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none " onClick={openPdfInNewTab}>
        View & download Report
      </button>
    </div>
  );
}