import React, { useEffect, useState } from 'react';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { Doughnut, Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { useDispatch, useSelector } from 'react-redux';
import { updateAllViewedProfiles, updateRecruiterInfo } from '../../Redux/recruiterSlice';
import { useNavigate } from 'react-router-dom';
import { Card } from 'flowbite-react';
import { FaArrowRightToBracket } from 'react-icons/fa6';

const RecruiterDashCom = () => {


    const { currentUser } = useSelector((state) => state.user);
    const { currentRecruiter } = useSelector((state) => state.recruiter);

    const [lastViewedProfiles, setLastViewedProfiles] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();




    const fetchRecruiterInfo = async () => {
        if (currentUser && currentRecruiter) {
            try {
                const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/recruiter/getRecruiter/${currentRecruiter._id}`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include',
                });

                const data = await res.json();
                if (res.ok) {
                    dispatch(updateRecruiterInfo(data.user));
                    dispatch(updateAllViewedProfiles(data.viewedProfiles));
                    const lastFiveIds = data.viewedProfiles.slice(-5);
                    setLastViewedProfiles([...lastFiveIds]);
                } else {
                    console.error("Failed to fetch recruiter:", data.message);
                }
            } catch (err) {
                console.error("Error fetching recruiter info:", err);
            }
        }
    };

    //------------------ handle go to profile ---------------------
    const handleViewProfile = (candidate) => {
        navigate(`/filter-candidate/profile`, { state: { candidate } });
    }


    useEffect(() => {
        if (currentUser && currentRecruiter) {
            fetchRecruiterInfo();
        }
    }, []);

    // Data for Doughnut Chart
    const donutData = {
        labels: ['Full-Time', 'Part-Time', 'Internship'],
        datasets: [
            {
                data: [47.3, 33.3, 19.4],
                backgroundColor: ['#6A0DAD', '#FF6384', '#36A2EB'],
                hoverBackgroundColor: ['#5e0b95', '#ff4b74', '#2f90d6'],
                borderWidth: 1,
            }
        ]
    };

    // Data for Bar Chart
    const barData = {
        labels: ['Accounting', 'IT', 'Finance', 'HR', 'Administration', 'Marketing', 'Sales'],
        datasets: [
            {
                label: 'Applications',
                data: [12, 15, 17, 22, 9, 18, 14],
                backgroundColor: '#6A0DAD',
                borderRadius: 10
            }
        ]
    };

    // Options for the Bar chart
    const barOptions = {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    return (
        <div className="p-8 bg-white rounded-lg shadow-lg grid grid-cols-2 gap-6">
            {/* Top Statistics */}
            <div className="grid grid-cols-4 gap-4 col-span-2">
                <StatBox title="Total Applicants" value="533" percentage="12%" isPositive={true} />
                <StatBox title="Shortlisted Candidates" value="98" percentage="-0.7%" isPositive={false} />
                <StatBox title="Hired Candidates" value="21" percentage="12%" isPositive={true} />
                <StatBox title="Gender Diversity Ratio" value="67.98%" />
            </div>

            {/* Donut Chart Section */}
            <div className="col-span-1 bg-gray-50 p-4 rounded-md shadow">
                <h3 className="text-lg font-semibold mb-4">Analytics</h3>
                <Doughnut data={donutData} />
                <div className="flex justify-around mt-4">
                    <div className="flex items-center"><span className="w-4 h-4 mr-2 rounded-full bg-purple-700"></span> Full-Time</div>
                    <div className="flex items-center"><span className="w-4 h-4 mr-2 rounded-full bg-pink-500"></span> Part-Time</div>
                    <div className="flex items-center"><span className="w-4 h-4 mr-2 rounded-full bg-blue-500"></span> Internship</div>
                </div>
            </div>

            {/* Bar Chart Section */}
            {/* <div className="col-span-1 bg-gray-50 p-4 rounded-md shadow">
                <h3 className="text-lg font-semibold mb-4">Applications received by department</h3>
                <Bar data={barData} options={barOptions} />
            </div> */}



            {/* viewed profile section */}
            <div>
                <Card className="max-w-sm">
                    <div className="mb-4 flex items-center justify-between">
                        <h5 className="text-xl font-bold leading-none text-gray-700 ">Viewed Profiles</h5>
                        <a href="/dashboard?tab=viewedCandidates" className="text-sm font-medium text-cyan-600 hover:underline ">
                            View all
                        </a>
                    </div>
                    <div className="flow-root">
                        <ul className="divide-y divide-gray-200 ">
                            {lastViewedProfiles.map((candidate, index) => (
                                <li key={index} className="py-3 sm:py-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="shrink-0">
                                            <img
                                                alt="Candidate Picrure"
                                                height="32"
                                                src={candidate.profilePicture}
                                                width="32"
                                                className="rounded-full"
                                            />
                                        </div>
                                        <div className="min-w-0 flex-1">
                                            <p className="truncate text-sm font-medium text-gray-900 ">{candidate.name}</p>
                                            <p className="truncate text-xs text-gray-500 ">{candidate.email}</p>
                                        </div>
                                        <div className="bg-sky-600 rounded-full hover:bg-sky-800" onClick={() => handleViewProfile(candidate)}>
                                            <FaArrowRightToBracket className="p-3 text-white" size={40} />
                                        </div>

                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Card>
            </div>

        </div>
    );
};

// Stat Box Component for the top section
const StatBox = ({ title, value, percentage, isPositive }) => (
    <div className="bg-gray-100 p-4 rounded-md shadow text-center">
        <h4 className="text-gray-600 text-sm">{title}</h4>
        <div className="flex justify-center items-center text-2xl font-bold">
            <span>{value}</span>
            {percentage && (
                <span className={`ml-2 flex items-center text-sm ${isPositive ? 'text-green-500' : 'text-red-500'}`}>
                    {isPositive ? <FaArrowUp /> : <FaArrowDown />} {percentage}
                </span>
            )}
        </div>
    </div>
);

export default RecruiterDashCom;