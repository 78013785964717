import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Alert, Button, Spinner } from "flowbite-react";
import { HiInformationCircle, HiUser } from 'react-icons/hi';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { useState } from 'react';
import { signInStart, signInSuccess, signInFailure } from '../../../Redux/user/userSlice.js';
import { updateAllViewedProfiles, updateRecruiterInfo } from '../../../Redux/recruiterSlice.js';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import GoogleAuth from '../../../components/googleAuth/GoogleAuth.jsx';



export default function WindowLogin() {


    const [formData, setFormData] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [showPassword, setShowPassword] = useState('');

    const { loading, error } = useSelector((state) => state.user);

    const cookies = new Cookies();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const from = location.state?.from?.pathname || '/';
    //saving form data
    const handleChange = (e) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.id]: e.target.value });
    }
    //toggle Eye
    const toggleEye = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    }
    //------------------------------------  handing signin function ---------------------------
    const handleSignin = async (e) => {
        e.preventDefault();

        if (!formData.email) {
            return setUserEmail('Enter your email.');
        }

        if (!formData.password) {
            return setUserPassword('Enter your password.')
        }



        try {
            dispatch(signInStart());

            const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/signin`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(formData)
            })

            const data = await res.json();

            if (data.success === false) {
                dispatch(signInFailure(data.message));
                setErrorMessage(data.message);
                return;
            }

            if (res.ok) {
                dispatch(signInSuccess(data.user));
                cookies.set('access_token', data.token);
                // ---------------------if user is Admin ----------------------
                if (data.user.isAdmin) {
                    navigate('/dashboard?tab=dash');

                    //---------------------- if user is Recruiter ---------------------
                } else if (data.user.isRecruiter && data.user.recruiterInfoId !== '') {
                    try {
                        const recruiterRes = await fetch(`${process.env.REACT_APP_BACKEND}/api/recruiter/getRecruiter/${data.user.recruiterInfoId}`, {
                            method: 'GET',
                            headers: { 'Content-Type': 'application/json' },
                            credentials: 'include',

                        });

                        const recruiterInfo = await recruiterRes.json();

                        if (recruiterRes.ok) {
                            dispatch(updateRecruiterInfo(recruiterInfo.user));
                            dispatch(updateAllViewedProfiles(recruiterInfo.viewedProfiles));
                            navigate('/dashboard?tab=recruiterDash')
                        }
                    } catch (error) {
                        dispatch(signInFailure(error.message))
                        console.log(error)
                    }

                    // -------------------- if user is Candidate --------------------
                } else {
                    
                    // Extract query string from location.search
                    const queryString = location.search;
                    const redirectPath = location.state?.from?.pathname || '/';
                    const finalRedirect = `${redirectPath}${queryString}`;
                    // Redirect with the query string
                    navigate(finalRedirect, { replace: true });
                }
            }

        } catch (error) {
            dispatch(signInFailure(error.message));
            console.log(error);
        }

    }

    return (
        <div className='flex flex-col md:flex-row-reverse'>
            <div className='w-full'>
                <div className=' w-auto  h-auto px-2 py-2 flex flex-col justify-center items-center rounded-lg bg-opacity-0'>
                    <div className='flex flex-col-reverse justify-center items-center'>
                        <div className='flex flex-row py-3 gap-1 -mt-4'>
                            <HiUser className='h-5 w-5 ' color='white' />
                            <h1 className='text-gray-100 text-[14px] text-center mb-3 font font-semibold mt-1'>SignIn </h1>
                        </div>
                        {/* <img className='w-20 h-auto' alt='horg logo' src="./images/logo-blue.png" /> */}
                    </div>
                    <form className="flex justify-center max-w-md flex-col gap-3 w-full" onSubmit={handleSignin}>
                        <div>
                            <div class="relative">
                                <input type="text" id="email" aria-describedby="email_help" class="block text-xs px-2 pb-1 pt-2 w-full text-gray-200 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer" placeholder=" " onChange={handleChange} />
                                <label for="email" class="absolute text-sm text-gray-100  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-gray-700 dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Email</label>
                            </div>
                            {userEmail && <p class="mt-1 text-xs text-red-700 dark:text-red-700">{userEmail}</p>}
                        </div>
                        <div>
                            <div className='flex flex-row-reverse'>
                                <div class="relative w-full">
                                    <input type={`${showPassword ? 'text' : 'password'}`} id="password" aria-describedby="password_help" class="block text-xs px-2 pb-1 pt-2 w-full text-gray-200 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer" placeholder=" " onChange={handleChange} />
                                    <label for="password" class="absolute text-sm text-gray-100 dark:text-blue-600 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-gray-700 dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Password</label>
                                </div>
                                {showPassword ? (
                                    <div className='absolute p-2 cursor-pointer'><FaRegEye onClick={toggleEye} color='grey' /></div>
                                ) : (
                                    <div className='absolute p-2 cursor-pointer'><FaRegEyeSlash onClick={toggleEye} color='grey' /></div>
                                )}
                            </div>
                            {userPassword && <p class="mt-1 text-xs text-red-700 dark:text-red-700">{userPassword}</p>}
                        </div>
                        {/* <div className="flex items-center gap-2">
                        <Checkbox id="remember" />
                        <Label htmlFor="remember" className='text-white'>Remember me</Label>
                    </div> */}
                        {errorMessage && <Alert className='text-sm' color="failure" icon={HiInformationCircle}>{errorMessage}</Alert>}
                        {error && <Alert className='text-[11px]' color="failure" icon={HiInformationCircle}>{error}</Alert>}
                        <button disabled={loading} className='mt-1 w-full py-1 bg-sky-500 rounded-lg text-xs text-white hover:bg-sky-700' type="submit">
                            {loading ? (
                                <>
                                    <Spinner size='sm' />
                                </>
                            ) : 'SignIn'}
                        </button>
                        <GoogleAuth />
                        <div className='flex flex-col'>
                            <p className='text-gray-300 text-[10px]'>Don't have an account? <b><Link to='/signup' className='text-blue-500 text-[11px]'>SignUp</Link></b></p>
                            <Link to='/forgotPassword' className='text-blue-500 text-[10px]'>Forgot password?</Link>
                        </div>
                    </form>
                </div >
            </div >
        </div>
    )
}