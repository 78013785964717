import React, { useState, useCallback, useRef, useEffect } from 'react';
import Lottie from "lottie-react";
import cube from "../assets/cube.json";
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentUser } from '../Redux/user/userSlice';
import { useFooterVisibility } from '../components/FooterVisibilityContext.jsx';
import {useNavbarVisibility} from '../components/NavbarVisibilityContext.jsx'

function ChooseSkill() {
    const { currentUser } = useSelector((state) => state.user);

    const {setIsNavbarVisible} = useNavbarVisibility();
    const {setIsFooterVisible} = useFooterVisibility();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedSkill, setSelectedSkill] = useState("Choose Skill");
    const [isClosing, setIsClosing] = useState(false); // State for close animation
    const [error, setError] = useState(null); // Error message if no skill is selected
    const [recruiterLoading, setRecruiterLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const dropdownRef = useRef(null); // Reference for dropdown

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleSkillSelect = (skill) => {
        setSelectedSkill(skill);
        setIsDropdownOpen(false);
        setError(null); // Reset any previous errors
    };

    const toCamelCase = (str) => {
        return str
            .split(' ')
            .map((word, index) =>
                index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join('');
    };

    //------------------------------ Handle Setting Major Skill----------------------------------
    const handleSubmitSkill = useCallback(async () => {
        if (selectedSkill === "Choose Skill") {
            setError("Please select a skill before proceeding.");
            return;
        }

        setIsClosing(true); // Trigger closing animation
        const skillData = { majorSKill: toCamelCase(selectedSkill) };

        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/${currentUser._id}/addMajorSkill`, {
                method: "PUT",
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(skillData)
            });

            if (res.ok) {
                const data = await res.json();
                dispatch(updateCurrentUser(data.user));
                setTimeout(() => {
                    // After skill selection, redirect to the original page, including the query string
                    const redirectUrl = location.state?.from?.pathname || '/';
                    const searchParams = location.state?.from?.search || '';
                    setIsFooterVisible(true);
                    setIsNavbarVisible(true);
                    navigate('/userForm');
                }, 300); // Match the animation duration
            } else {
                setError("Failed to submit skill. Please try again.");
            }
        } catch (error) {
            console.log(error);
            setError("An error occurred while submitting your skill.");
        }
    }, [selectedSkill, navigate, currentUser, location.state?.from]);


    // --------------------------------- Function to set the user as a recruiter --------------------------------
    const setUserAsRecruiter = async () => {
        setIsClosing(true); // Trigger closing animation

        try {
            setRecruiterLoading(true);
            const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/users/setRecruiter/${currentUser._id}`, {
                method: "PUT",
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ isRecruiter: true, isAdmin: false }) // Setting user as recruiter
            });

            if (res.ok) {
                setRecruiterLoading(false);
                const data = await res.json();
                dispatch(updateCurrentUser(data.user));
                setTimeout(() => {
                    setIsFooterVisible(true);
                    setIsNavbarVisible(true);
                    navigate('/recruiter-form');
                }, 300); // Match the animation duration
            } else {
                setError("Failed to set user as recruiter. Please try again.");
            }
        } catch (error) {
            console.log(error);
            setError("An error occurred while setting user as recruiter.");
        }
    };

    //disable footer and navbar on render this page
    useEffect(() => {
        setIsFooterVisible(false);
        setIsNavbarVisible(false);
    },[]);

    // Handle clicks outside the dropdown to close it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false); // Close the dropdown
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className={`fixed inset-0 flex items-center justify-center bg-gray-800 
                         ${isClosing ? 'opacity-0 transition-opacity duration-300' : 'opacity-100'}`}>
            <div className={`bg-white mt-8 p-8 rounded-lg shadow-lg w-3/4 max-w-lg text-center
                            transform ${isClosing ? 'scale-95' : 'scale-100'}
                            transition-transform duration-300`}>
                <div className='h-40 w-40 mx-auto -my-12'>
                    <Lottie className='opacity-70' animationData={cube} />
                </div>
                <p className="mt-2 text-sm font-medium text-gray-700">
                    Please enter your major skill from the options below
                    <span className='text-red-600 text-base'>*</span><br />
                    <span className='text-gray-500 text-[10px]'>
                        It will help us to sort and showcase your profile to the recruiters.
                    </span>
                </p>
                <div className="relative w-full flex justify-center">
                    <button
                        onClick={toggleDropdown}
                        className="my-2 text-base text-gray-700 bg-white border border-blue-600 focus:ring-1
                        focus:outline-none focus:ring-blue-300 font-semibold rounded-lg 
                        px-10 py-2 flex items-center transition ease-in-out duration-300"
                    >
                        {selectedSkill}
                        <svg className="w-4 h-4 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                        </svg>
                    </button>
                    {isDropdownOpen && (
                        <div ref={dropdownRef} className="absolute top-full mt-2 z-10 w-56 max-h-48 bg-white divide-y divide-gray-100 rounded-lg shadow-lg overflow-y-auto">
                            <ul className="p-4 space-y-3 text-sm text-gray-700" aria-labelledby="dropdownRadioButton">
                                {['Data Analyst', 'Java Developer', 'Business Analyst', 'Business Intelligence', 'C++ Developer', 'Python Developer', 'Others']
                                    .map((job, index) => (
                                        <li key={index}>
                                            <div className="flex items-center">
                                                <input
                                                    type="radio"
                                                    name="default-radio"
                                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                                                    onClick={() => handleSkillSelect(job)}
                                                />
                                                <label className="ml-2 text-sm font-medium text-gray-900">{job}</label>
                                            </div>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    )}
                </div>
                {error && <p className="text-red-500 text-sm mt-2">{error}</p>}

                <button
                    onClick={handleSubmitSkill}
                    className="mt-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5"
                >
                    Proceed
                </button>

                <p className='text-gray-400 text-lg font-semibold my-3'>/OR</p>

                <button
                    onClick={setUserAsRecruiter} 
                    disabled={recruiterLoading} 
                    className={`text-white ${recruiterLoading ? 'bg-gray-500 cursor-not-allowed' : 'bg-teal-500 hover:bg-teal-700'} focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5`}
                >
                    {recruiterLoading ? 'Loading...' : 'Proceed as a Recruiter'}
                </button>
            </div>
        </div>
    );
}

export default ChooseSkill;
