import React from "react";
import Lottie from "lottie-react";
import endTest from "../assets/endTest.json";
import { useNavigate } from "react-router-dom";


export default function EndTest() {
    const navigate = useNavigate();

    return (
        <div className="m-5 flex flex-row items-center justify-center">
            <div class="max-w-lg h-screen lg:h-96 rounded-lg flex flex-col justify-center items-center">
                <div>
                    <Lottie animationData={endTest} />
                </div>
                <div className="flex flex-col justify-center items-center -mt-12">
                    <p className="my-2">
                        Test has already ended. Close this tab.
                    </p>
                    
                </div>
            </div>
        </div>
    );
}