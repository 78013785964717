import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    marksObtained: 0,
    mcqRightAnswerCount: 0,
    codingRightAnswerCount: 0,
    isPDFSent: false,
    questions: [],
    answers: [],
    loading: false,
    timeTaken: null,
    error: null,
    Violations:0,
}

const skillTestSlice = createSlice({
    name: 'skillTest',
    initialState,
    reducers: {

        startSkillTest: (state) => {
            state.marksObtained = 0;
            state.mcqRightAnswerCount = 0;
            state.codingRightAnswerCount = 0;
            state.isPDFSent = false;
            state.questions = [];
            state.answers = [];
            state.timeTaken = null;
            state.loading = true;
            state.error = null;
            state.Violations=0;
        },

        saveTestQuestions: (state, action) => {
            state.questions = [...state.questions, ...action.payload];
            state.answers = [];
            state.loading = true;
            state.error = null;
        },

        saveTestQuestionSuccess: (state) => {
            state.loading = false;
            state.error = null;
        },



        saveAndNextSuccess: (state, action) => {
            const existingIndex = state.answers.findIndex(answer => answer.qID === action.payload.qID);

            if (existingIndex !== -1) {
                // Replace the existing object with the new one
                state.answers[existingIndex] = action.payload;
            } else {
                // If no matching qID is found, add the new answer
                state.answers = [...state.answers, action.payload];
            }
        },

        addMarks: (state, action) => {
            state.marksObtained = action.payload;
        },

        submitSkillTest: (state, action) => {
            state.timeTaken = action.payload;
        },

        increaseMcqRightAnswerCount: (state) => {
            state.mcqRightAnswerCount = state.mcqRightAnswerCount + 1;
        },

        decreaseMcqRightAnswerCount: (state) => {
            state.mcqRightAnswerCount = state.mcqRightAnswerCount - 1;
        },

        increaseCodingRightCount: (state) => {
            state.codingRightAnswerCount = state.codingRightAnswerCount + 1;
        },
        decreaseCodingRightCount: (state) => {
            state.codingRightAnswerCount = state.codingRightAnswerCount - 1;
        },

        isPDFSentSuccess: (state) => {
            state.isPDFSent = true;
        },
        increaseViolations: (state) => {
            state.Violations = state.Violations + 1;
        },

        removeSkillTestData: (state) => {
            state.marksObtained = 0;
            state.mcqRightAnswerCount = 0;
            state.codingRightAnswerCount = 0;
            state.isPDFSent = false;
            state.questions = [];
            state.answers = [];
            state.timeTaken = null;
            state.loading = true;
            state.error = null;
            state.Violations=0;
        },

    }
})


export const { startSkillTest,
    saveTestQuestions,
    saveTestQuestionSuccess,
    saveAndNextSuccess,
    addMarks,
    submitSkillTest,
    increaseMcqRightAnswerCount,
    decreaseMcqRightAnswerCount,
    increaseCodingRightCount,
    decreaseCodingRightCount,
    isPDFSentSuccess,
    removeSkillTestData,
    increaseViolations

} = skillTestSlice.actions;

export const skillTestReducer = skillTestSlice.reducer;