import { app } from "../../firebase.js";
import { useDispatch } from "react-redux";
import { signInStart, signInSuccess, signInFailure } from "../../Redux/user/userSlice.js";
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate, useLocation } from "react-router-dom";
import { updateAllViewedProfiles, updateRecruiterInfo } from "../../Redux/recruiterSlice.js";



export default function GoogleAuth() {

    const auth = getAuth(app);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const location = useLocation();

    const handleGoogleClick = async () => {
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({ prompt: 'select_account' });
        try {
            dispatch(signInStart());
            const resultfromGoogle = await signInWithPopup(auth, provider);
            const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/googleSignin`, {
                method: "POST",
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    name: resultfromGoogle.user.displayName,
                    email: resultfromGoogle.user.email,
                    googlePhotoUrl: resultfromGoogle.user.photoURL,
                }),
            })

            const data = await res.json();

            if(res.status === 404){
                return dispatch(signInFailure("No user found with this email! Sign up now."));
            }

            if (res.ok) {
                dispatch(signInSuccess(data));
                // Check if the user needs to select a skill
                if (!data.isAdmin && !data.isRecruiter && data.majorSKill === '') {
                    navigate('/chooseSkill', {
                        state: { from: location.state?.from || location },  // Pass the original desired location
                        replace: true,
                    });
                } else {
                    dispatch(signInSuccess(data));

                    //fetching Recruiter Info and updating to the REDUX
                    if (data.isRecruiter && data.recruiterInfoId !== '') {
                        try {
                            const recruiterRes = await fetch(`${process.env.REACT_APP_BACKEND}/api/recruiter/getRecruiter/${data.recruiterInfoId}`, {
                                method: 'GET',
                                headers: { 'Content-Type': 'application/json' },
                                credentials: 'include',
                            });

                            const recruiterInfo = await recruiterRes.json();
                            if (recruiterRes.ok) {
                                dispatch(updateRecruiterInfo(recruiterInfo.user));
                                dispatch(updateAllViewedProfiles(recruiterInfo.viewedProfiles));
                            }
                        } catch (error) {
                            console.log(error)
                        }
                    }
                    // Extract query string from location.search
                    const queryString = location.search;
                    const redirectPath = location.state?.from?.pathname || '/';
                    const finalRedirect = `${redirectPath}${queryString}`;

                    // Redirect with the query string
                    navigate(finalRedirect, { replace: true });
                }
            }

        } catch (error) {
            dispatch(signInFailure(error.message));
            console.log(error);
        }
    }

    return (
        <button onClick={handleGoogleClick} className=" mt-2 bg-gray-600 text-white text-xs flex justify-center items-center gap-2 py-2 rounded-md hover:bg-gray-500 hover:text-gray-100" type="button">
            <img className="w-5 h-5 mr-2 " src="./images/google.png.png" alt="google img" />
            Continue with Google
        </button>
    )
}