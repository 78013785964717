
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import "react-datepicker/dist/react-datepicker-cssmodules.css";


function DashConsult() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        service: '',
        date: null,
        time: '',
        query: '',
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };
    const handleDateChange = (date) => {
        setFormData((prevData) => ({ ...prevData, date }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form Submitted', formData);
    };
    return (
        <div className="max-w-lg mx-auto p-8 bg-gradient-to-b from-blue-50 to-white shadow-lg rounded-lg">
            <h2 className="text-3xl font-bold text-center mb-8 text-blue-800">Book a Consultation</h2>
            <form onSubmit={handleSubmit} className="space-y-5">
                {/* Name Field */}
                <label className="block font-medium text-lg text-blue-700">
                    Name:
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full p-3 mt-2 border rounded-lg shadow-inner"
                        required
                    />
                </label>
                {/* Email Field */}
                <label className="block font-medium text-lg text-blue-700">
                    Email:
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full p-3 mt-2 border rounded-lg shadow-inner"
                        required
                    />
                </label>
                {/* Phone Field */}
                <label className="block font-medium text-lg text-blue-700">
                    Phone:
                    <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="w-full p-3 mt-2 border rounded-lg shadow-inner"
                        required
                    />
                </label>
                {/* Service Selection */}
                <label className="block font-medium text-lg text-blue-700">
                    Select Service:
                    <select
                        name="service"
                        value={formData.service}
                        onChange={handleChange}
                        className="w-full p-3 mt-2 border rounded-lg shadow-inner"
                        required
                    >
                        <option value="">Select Service</option>
                        <option value="consulting">Consulting</option>
                        <option value="strategy">Strategy</option>
                        <option value="development">Development</option>
                        <option value="design">Design</option>
                    </select>
                </label>
                {/* Date and Time Picker */}
                <label className="block font-medium text-lg text-blue-700">
                    Preferred Date & Time:
                    <div className="flex space-x-3 mt-2">
                        <DatePicker
                            selected={formData.date}
                            onChange={handleDateChange}
                            className="p-3 border rounded-lg shadow-lg flex-1"
                            placeholderText="Select Date"
                            required
                        />
                        <input
                            type="time"
                            name="time"
                            value={formData.time}
                            onChange={handleChange}
                            className="p-3 border rounded-lg shadow-lg flex-1"
                            required
                        />
                    </div>
                </label>
                {/* Query Section */}
                <label className="block font-medium text-lg text-blue-700">
                    Your Query:
                    <textarea
                        name="query"
                        value={formData.query}
                        onChange={handleChange}
                        className="w-full p-3 mt-2 border rounded-lg shadow-inner"
                        rows="4"
                        required
                    ></textarea>
                </label>
                {/* Submit Button */}
                <button
                    type="submit"
                    className="w-full bg-gradient-to-r from-blue-600 to-blue-500 text-white p-3 rounded-lg shadow-xl hover:shadow-2xl transition"
                >
                    Submit
                </button>
            </form>
        </div>
    );
}
export default DashConsult;