import { Link } from "react-router-dom"

export default function DashTests() {


    return(
        <div className="h-full w-full flex flex-col items-center mt-14">
            <h1>
                Haven't attempted a test yet? 
            </h1>
            <Link className="text-white px-4 py-2 bg-sky-600 rounded-md my-4" to='/selectSkill'>Take a test</Link>
        </div>
        
    )
}