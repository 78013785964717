import { useState } from 'react';
import { Helmet } from 'react-helmet-async';

export default function About() {


    const sidesData = [
        {
            title: "1. Think You’re Ready? Test Yourself Now!",
            deatils: "Think you’re ready to take on the best? Test yourself now and uncover your true potential! With our skill assessments, you’ll get a precise snapshot of your strengths and areas to improve. It’s more than just a score—it’s your chance to challenge yourself, track your growth, and build the expertise needed to stand out. Take the first step towards mastering your skills today!",
            img: "./images/about/opt-1.jpg"
        },
        {
            title: "2. Skills That Matter in Today’s Job Market",
            deatils: "Join the ranks of top performers! Test your skills, improve your score, and climb our leaderboard. Get noticed by peers and employers.",
            img: "./images/about/opt-2.webp"
        },
        {
            title: "3. Climb the Leaderboard and Get Recognized!",
            deatils: "Join the ranks of top performers! Test your skills, improve your score, and climb our leaderboard. Get noticed by peers and employers.",
            img: "./images/about/opt-3.avif"
        },
        {
            title: "4. Push Your Limits with Advanced Tests",
            deatils: "Already a pro? Take our advanced skill tests and challenge yourself to reach new heights. Test your expertise against the toughest standards and set yourself apart.",
            img: "./images/about/opt-4.jpg"
        },
        {
            title: "5. Get Your Results Immediately!",
            deatils: "No waiting—get immediate results after completing your skill test. Understand your strengths and areas for improvement right away, so you can start working on your next step.",
            img: "./images/about/opt-5.jpeg"
        },
    ]

    const [selectedIndex, setSelectedIndex] = useState(0);


    return (
        <>

            <div className="flex flex-col items-center w-full overflow-y-auto top-0 -mt-20">

                {/* HERO section */}
                <div className="hero-section w-full  flex justify-center h-[620px] bg-blue-200  overflow-x-hidden overflow-y-auto"
                    style={{ backgroundImage: 'url(./images/about-bg.jpg)', backgroundSize: "cover" }}>
                    {/* <img
                        src="./images/about-bg.jpg"
                        alt="elemental-Design"
                        className="absolute h-auto w-full opacity-15 width-container mx-auto overflow-y-hidden object-cover"
                    /> */}
                    <div className="absolute md:w-[1080px] flex flex-col items-center mt-[145px] width-container ">

                        <h1 className="font-semibold font-josefinSans text-[200px] text-center text-blue-700 mt-10 opacity-80">About Us</h1>
                        <p className="text-center text-sm font-semibold text-gray-100 opacity-60 -mt-20">Streamline hiring with tailored skill tests, helping companies find qualified candidates faster and build stronger, more capable teams.</p>

                        {/* <img className="w-[1100px] relative rounded-lg mb-10" src="./images/hero-img1.webp" alt="img-1" /> */}
                    </div>

                </div>


                <div className="flex flex-row-reverse justify-between gap-6 h-auto w-full max-w-[1140px] items-center mt-28">
                    {/* photo */}
                    <div className="h-auto w-[565px]">
                        <div className="w-[560px] h-[320px] bg-sky-300 rounded-lg shadow-lg"
                            style={{ backgroundImage: `url(${sidesData[selectedIndex].img})`, backgroundSize: "cover" }}></div>
                        <h3 className="text-gray-700 font-semibold border-gray-700 mt-4">{sidesData[selectedIndex].title}</h3>
                        <p className="text-gray-600 text-[14px] mt-2">{sidesData[selectedIndex].deatils}</p>
                    </div>
                    {/* Text Section */}
                    <div className="h-auto flex flex-col items-start">
                        {sidesData.map((item, index) => (
                            <div
                                key={index}
                                className={`p-5 cursor-pointer ${selectedIndex === index ? "bg-sky-100 rounded-md" : ""}`}
                                onClick={() => setSelectedIndex(index)}
                            >
                                <h3 className="text-gray-700 font-semibold border-gray-700">{item.title}</h3>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='bg-blue-800 bg-opacity-90 w-full h-[500px] mt-20 flex flex-col item-center justify-center mx-auto'>
                    <div className='w-full max-w-[1140px] mx-auto'>
                        <div className='h-auto w-full flex flex-row justify-center gap-8 mt-4 '>
                            <div className='bg-gray-200 h-44 w-80 rounded-lg border-2'
                                style={{ backgroundImage: 'url(./images/about/crd-bg-1.jpg)', backgroundSize: "cover" }}></div>

                            <div className='w-[450px] h-auto flex flex-col items-start justify-center'>
                                <h1 className='text-3xl text-gray-100 font-semibold text-start my-5'>Trusted by Our Clients</h1>
                                <p className='text-sm text-gray-400 text-start'>We’re proud to support and empower our clients! Here’s a glimpse at the amazing organizations and individuals who’ve chosen us to help reach their goals. Thank you for being part of our journey!</p>
                            </div>
                        </div>
                        <div className='h-auto w-full flex flex-row justify-center items-center gap-4 mt-4'>


                            <div className='bg-gray-200 h-44 w-80 rounded-lg border-2'
                                style={{ backgroundImage: 'url(./images/about/crd-bg-2.jpg)', backgroundSize: "cover" }}></div>

                            <div className='bg-gray-200 h-52 w-96 rounded-lg border-2'
                                style={{ backgroundImage: 'url(./images/about/crd-bg-3.jpg)', backgroundSize: "cover" }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}