import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Alert, Button, Spinner } from "flowbite-react";
import { HiInformationCircle, HiUser } from 'react-icons/hi';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { signInStart, signInSuccess, signInFailure, removeErrorAndLoad } from '../../../Redux/user/userSlice.js';
import { updateAllViewedProfiles, updateRecruiterInfo } from '../../../Redux/recruiterSlice.js';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';

import GoogleAuth from '../../../components/googleAuth/GoogleAuth.jsx';
import { RxCross1 } from 'react-icons/rx';
import { useNavbarVisibility } from '../../../components/NavbarVisibilityContext.jsx';
import { useFooterVisibility } from '../../../components/FooterVisibilityContext.jsx';


export default function Signin() {


    const [formData, setFormData] = useState({});
    const [errorMessage, setErrorMessage] = useState('');

    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [showPassword, setShowPassword] = useState('');
    const [isVisible, setIsVisible] = useState(false);

    const { loading, error } = useSelector((state) => state.user);

    const { setIsNavbarVisible } = useNavbarVisibility();
    const { setIsFooterVisible } = useFooterVisibility();


    const cookies = new Cookies();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();


    const from = location.state?.from?.pathname || '/';



    //saving form data
    const handleChange = (e) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.id]: e.target.value });
    }

    // go to thee prvious page
    const handleGoBack = () => {
        setIsFooterVisible(true);
        setIsNavbarVisible(true);
        navigate('/');
    }


    //toggle eye button
    const handleEyePassword = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    }



    useEffect(() => {
        dispatch(removeErrorAndLoad());
        setIsVisible(true);
        setIsFooterVisible(false);
        setIsNavbarVisible(false);
    }, []);


    //------------------------------------  handing signin function ---------------------------
    const handleSignin = async (e) => {
        e.preventDefault();

        if (!formData.email) {
            return setUserEmail('Enter your email.');
        }

        if (!formData.password) {
            return setUserPassword('Enter your password.')
        }



        try {
            dispatch(signInStart());

            const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/signin`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(formData)
            })

            const data = await res.json();

            if (data.success === false) {
                dispatch(signInFailure(data.message));
                setErrorMessage(data.message);
                return;
            }

            if (res.ok) {
                dispatch(signInSuccess(data.user));
                cookies.set('access_token', data.token);
                // ---------------------if user is Admin ----------------------
                if (data.user.isAdmin) {

                    setIsFooterVisible(true);
                    setIsNavbarVisible(true);
                    return navigate('/dashboard?tab=dash');

                    //---------------------- if user is Recruiter ---------------------
                } else if (data.user.isRecruiter && data.user.recruiterInfoId !== '') {
                    try {
                        const recruiterRes = await fetch(`${process.env.REACT_APP_BACKEND}/api/recruiter/getRecruiter/${data.user.recruiterInfoId}`, {
                            method: 'GET',
                            headers: { 'Content-Type': 'application/json' },
                            credentials: 'include',

                        });

                        const recruiterInfo = await recruiterRes.json();

                        if (recruiterRes.ok) {
                            dispatch(updateRecruiterInfo(recruiterInfo.user));
                            dispatch(updateAllViewedProfiles(recruiterInfo.viewedProfiles));
                            setIsFooterVisible(true);
                            setIsNavbarVisible(true);
                            return navigate('/dashboard?tab=recruiterDash')
                        }
                    } catch (error) {
                        dispatch(signInFailure(error.message))
                        console.log(error)
                    }

                    // -------------------- if user is Candidate --------------------
                } else {
                    setIsFooterVisible(true);
                    setIsNavbarVisible(true);
                    
                    // Extract query string from location.search
                    const queryString = location.search;
                    const redirectPath = location.state?.from?.pathname || '/';
                    const finalRedirect = `${redirectPath}${queryString}`;
                    // Redirect with the query string
                    return navigate(finalRedirect, { replace: true });
                }
            }

        } catch (error) {
            dispatch(signInFailure(error.message));
            console.log(error);
        }

    }


    if (!isVisible) {
        return null; // Don't render the banner if the consent is already given
    }

    return (
        <div className='h-auto w-screen  flex items-center justify-center  top-0 left-0 '>
            <img className='object-cover fixed inset-0 w-full h-full opacity-75' src="./images/auth-bg.jpg" alt="horg-logo" />
            <div className="w-[400px] my-6 mt-14 h-auto bg-white text-white p-6 flex flex-col items-center justify-center rounded-lg shadow-lg z-50">
                <RxCross1 className='text-gray-300 relative z-50 ml-[360px] -mt-4 hover:text-gray-400 cursor-pointer'
                    size={18}
                    onClick={handleGoBack} />
                <div className='flex flex-col items-center gap-3'>
                    <img className='w-24' src="./images/logo-blue.png" alt="horg logo" />
                    <div className='flex flex-col py-1 mb-3'>
                        <div className='flex flex-row justify-center gap-1 -mt-2'>
                            <HiUser className='h-4 w-4' color='gray' />
                            <h1 className='text-gray-500 text-[14px] text-center'>SignIn</h1>
                        </div>
                    </div>
                </div>
                <form className="flex justify-center max-w-lg flex-col gap-4 w-full" onSubmit={handleSignin}>

                    <div className='w-full'>
                        <div class="relative">
                            <input type="text" id="email" aria-describedby="email_help" className="block px-1 pb-1 pt-1 w-full text-[12px] text-gray-800 bg-transparent rounded-lg border-1 border-blue-600 appearance-none dark:text-white dark:blue-green-500 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " onChange={handleChange} />
                            <label for="email" className="absolute text-sm text-blue-800 dark:text-blue-600 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Email</label>
                        </div>
                        {userEmail && <p class="mt-1 text-xs text-red-700 dark:text-red-700">{userEmail}</p>}
                    </div>
                    <div>
                        <div className='flex flex-row-reverse'>
                            <div class="relative w-full">
                                <input type={`${showPassword ? 'text' : 'password'}`} id="password" aria-describedby="password_help" className="block px-1 pb-1 pt-1 w-full text-[12px] text-gray-800 bg-transparent rounded-lg border-1 border-blue-600 appearance-none dark:text-white dark:blue-green-500 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " onChange={handleChange} />
                                <label for="password" className="absolute text-sm text-blue-800 dark:text-blue-600 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Password</label>
                            </div>
                            {showPassword ? (
                                <div className='absolute p-3 cursor-pointer'><FaRegEye onClick={handleEyePassword} color='grey' className='pb-1' /></div>
                            ) : (
                                <div className='absolute p-3 cursor-pointer'><FaRegEyeSlash onClick={handleEyePassword} color='grey' className='pb-1' /></div>
                            )}
                        </div>
                        {userPassword && <p className="mt-1 text-xs text-red-700 dark:text-red-700">{userPassword}</p>}
                    </div>


                    {errorMessage && <Alert className='text-sm' color="failure" icon={HiInformationCircle}>{errorMessage}</Alert>}
                    {error && <Alert className='text-[12px]' color="failure" icon={HiInformationCircle}>{error}</Alert>}
                    <button disabled={loading} className='mt-1 w-full py-2 bg-sky-500 rounded-md text-sm text-white hover:bg-sky-700 -mb-2' type="submit">
                        {loading ? (
                            <>
                                <Spinner size='sm' />
                            </>
                        ) : 'SignIn'}
                    </button>
                    <GoogleAuth />
                    <div className='flex flex-col items-start gap-4'>
                        <p className='-mb-4  text-gray-600 text-xs'>Already have an account?

                            <Link to='/signup' className='text-blue-500 text-xs'>  SignUp</Link>

                        </p>
                        <Link to='/forgotPassword' className='text-blue-500 text-[12px]'>Forgot password?</Link>
                    </div>
                </form>
            </div>
        </div>
    );
}