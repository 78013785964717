import React, { useState, useEffect } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { LiaCookieBiteSolid } from "react-icons/lia";

export default function CookieBanner() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Check if cookie consent is already set
        if (!document.cookie.includes('cookieConsent=true')) {
            setIsVisible(true);
        }
    }, []);

    const acceptCookies = () => {
        // Set the cookie with SameSite and Secure attributes
        document.cookie = "cookieConsent=true; path=/; max-age=31536000; SameSite=None; Secure";
        setIsVisible(false);
    };

    const declineCookies = () => {
        // Handle cookie decline or just hide the banner
        setIsVisible(false);
    };

    if (!isVisible) {
        return null; // Don't render the banner if the consent is already given
    }

    return (
        <div className='h-screen w-screen bg-black bg-opacity-60 flex items-center justify-center fixed top-0 left-0 z-50'>
            <div className="w-[550px] h-auto bg-gray-800 text-white p-6 flex flex-col items-center justify-center rounded-lg shadow-lg">

                <LiaCookieBiteSolid color='white' size={110} />

                <p className="text-sm text-center mb-4">
                    This website uses cookies to enhance your experience and for authentication. By continuing, you agree to the use of cookies. Read our
                    <a href="https://bendainfotech.com/privacy-policy/" className="text-green-400 ml-1">Privacy Policy</a>.
                </p>

                <div className="flex space-x-4">
                    <button
                        className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded flex items-center space-x-2"
                        onClick={acceptCookies}
                    >
                        <FaCheck /> <span>Accept Cookies</span>
                    </button>
                </div>
            </div>
        </div>

    );
}