import { useEffect, useState } from "react";
import { Button } from "flowbite-react";
import { FaCheckCircle, FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { useNavigate, useSearchParams } from "react-router-dom";

export default function ResetPassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [userConfirmPassword, setUserConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [touched, setTouched] = useState({
        password: false,
        confirmPassword: false
    });
    const [loading, setLoading] = useState(false);
    const [resMessage, setResMessage] = useState('');
    const [errorMeaasge, setErrorMessage] = useState('');
    const [isPasswordSet, setIsPasswordSet] = useState(false);
    const [buttonText, setButtonText] = useState('Reset Password');

    // Extract token and email from the URL query params
    const [searchParams] = useSearchParams();
    const resetToken = searchParams.get("token");
    const email = searchParams.get("email");

    const navigate = useNavigate();

    useEffect(() => {
        if (!resetToken || !email) {
            console.error("Invalid or missing token/email in URL");
        }
    }, [resetToken, email]);

    const handleEyePassword = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };

    const handleEyeConfirmPassword = (e) => {
        e.preventDefault();
        setShowConfirmPassword(!showConfirmPassword);
    };

    // Handle input change and validation
    const handlePasswordChange = (e) => {
        const { id, value } = e.target;
        setPassword(value);
        setTouched((prev) => ({ ...prev, [id]: true }));
        validatePasswords(value, confirmPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        const { id, value } = e.target;
        setConfirmPassword(value);
        setTouched((prev) => ({ ...prev, [id]: true }));
        validatePasswords(password, value);
    };

    const validatePasswords = (passwordValue, confirmPasswordValue) => {
        if (passwordValue === '') {
            setUserPassword('Set password.');
        } else {
            setUserPassword('');
        }

        if (confirmPasswordValue === '') {
            setUserConfirmPassword('Re-enter your password.');
        } else if (passwordValue && confirmPasswordValue !== passwordValue) {
            setUserConfirmPassword('Passwords do not match.');
        } else {
            setUserConfirmPassword('');
        }

        if (passwordValue && confirmPasswordValue && passwordValue === confirmPasswordValue) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();

        try {

            if(isPasswordSet){
                return navigate('/signin')
            };
            
            setLoading(true);
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/resetPassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,           // Use the email from query params
                    token: resetToken,       // Use the token from query params
                    newPassword: password    // The new password the user sets
                }),
            });

            const data = await response.json();

            setLoading(false);
            if (response.ok) {
                setErrorMessage('');
                setIsPasswordSet(true);
                setButtonText('Go to sign-in');
                setResMessage(data.message);
            } else {
                setResMessage('');
                setErrorMessage(data.message)
            }
        } catch (error) {
            console.error("Failed to reset password:", error);
            // Handle error on the page
            setResMessage('');
            setErrorMessage('Internal server error. Try again later.');
        }
    };

    return (
        <div className='w-screen h-screen flex flex-col items-center'>
            <h2 className="mb-3 mt-6 text-xl text-gray-700 font-semibold">Set your new password</h2>
            <form className='w-full mx-4 mt-12 md:w-[520px] h-auto py-10 flex flex-col items-center justify-center border shadow-lg rounded-lg' onSubmit={handleResetPassword}>

                {/* Password Field */}
                <div className="w-[90%] mb-2">
                    <div className='flex flex-row-reverse w-full'>
                        <div className="relative w-full">
                            <input
                                type={`${showPassword ? 'text' : 'password'}`}
                                id="password"
                                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-800 bg-transparent rounded-lg border-1 border-blue-600 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                onChange={handlePasswordChange}
                            />
                            <label
                                htmlFor="password"
                                className="ml-2 absolute text-sm text-blue-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4">
                                Password
                            </label>
                        </div>
                        {showPassword ? (
                            <div className='absolute p-4 cursor-pointer'><FaRegEye onClick={handleEyePassword} /></div>
                        ) : (
                            <div className='absolute p-4 cursor-pointer'><FaRegEyeSlash onClick={handleEyePassword} /></div>
                        )}
                    </div>
                    {touched.password && userPassword && <p className="mt-1 text-xs text-red-700">{userPassword}</p>}
                </div>

                {/* Confirm Password Field */}
                <div className="w-[90%]">
                    <div className='flex flex-row-reverse w-full'>
                        <div className="relative w-full">
                            <input
                                type={`${showConfirmPassword ? 'text' : 'password'}`}
                                id="confirmPassword"
                                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-800 bg-transparent rounded-lg border-1 border-blue-600 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                onChange={handleConfirmPasswordChange}
                            />
                            <label
                                htmlFor="confirmPassword"
                                className="ml-2 absolute text-sm text-blue-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4">
                                Confirm Password
                            </label>
                        </div>
                        {showConfirmPassword ? (
                            <div className='absolute p-4 cursor-pointer'><FaRegEye onClick={handleEyeConfirmPassword} /></div>
                        ) : (
                            <div className='absolute p-4 cursor-pointer'><FaRegEyeSlash onClick={handleEyeConfirmPassword} /></div>
                        )}
                    </div>
                    {touched.confirmPassword && userConfirmPassword && <p className="mt-1 text-xs text-red-700">{userConfirmPassword}</p>}
                </div>

                {errorMeaasge && (
                    <p className="mt-1 text-xs text-red-700">
                        {errorMeaasge}
                    </p>
                )}

                {resMessage && (
                    <div className="flex items-center gap-3 my-auto">
                        {/* Ensure the icon is rendered properly */}
                        <FaCheckCircle className="text-green-500 h-3 w-3" /> {/* Adjust the size for better visibility */}
                        <p className="mt-1 text-xs text-green-500">{resMessage}</p>
                    </div>
                )}

                {/* Submit Button */}
                <Button className='mt-3' type="submit" gradientDuoTone='cyanToBlue' disabled={!isFormValid || loading}>
                    {loading ? 'Loading...' : buttonText}
                </Button>
            </form>
        </div>
    );
}
