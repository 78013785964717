

import { Button } from "flowbite-react";
import { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";

export default function ForgotPassword() {
    const [userEmailError, setUserEmailError] = useState("");
    const [isExist, setIsExist] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [isMailSent, setIsMailSent] = useState(false);

    // Reset states when the email changes
    const handleChange = (e) => {
        e.preventDefault();
        setEmail(e.target.value);
        setIsExist(false); // Reset to allow verification of new email
        setUserEmailError(""); // Clear previous error message
        setResponseMessage(""); // Clear previous success message
    };

    const verifyUserAndSendMail = async (e) => {
        e.preventDefault();

        if (!isExist) {
            // Verify user existence
            try {
                setLoading(true);
                const res = await fetch(
                    `${process.env.REACT_APP_BACKEND}/api/user/getUser?email=${email}`,
                    {
                        method: "GET",
                        headers: { "Content-Type": "application/json" }
                    }
                );

                let data;

                console.log('Response Status:', res.status);  // Log response status to check status code

                if (res.ok) {
                    data = await res.json();
                    console.log('User data:', data);  // Log the parsed JSON data
                }

                setLoading(false);

                if (res.ok) {
                    setIsExist(true);
                    setUserEmailError(""); // Clear any error message
                    setResponseMessage("User found. Now you can send the reset mail request.");
                } else {
                    // In case the response is not ok, check if `data` exists and log it
                    data = await res.json();
                    console.log('Error response data:', data);  // Log error response data
                    setIsExist(false);
                    setResponseMessage(""); // Clear success message
                    setUserEmailError(data.message || "User not found.");
                }
            } catch (error) {
                setLoading(false);
                console.log(error);
                setResponseMessage('');
                setUserEmailError("Internal server error, try again later.");
            }
        } else {
            // Send verification mail if the user exists
            try {
                setLoading(true);

                const res = await fetch(
                    `${process.env.REACT_APP_BACKEND}/api/auth/forgotPassword`,
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ email: email }),
                    }
                );

                const data = await res.json();
                setLoading(false);

                if (res.ok) {
                    setResponseMessage("Password reset email sent successfully.");
                    setUserEmailError(""); // Clear error message
                    setIsMailSent(true);
                } else {
                    setResponseMessage(""); // Clear success message
                    setUserEmailError(data.message || "Failed to send reset email.");
                }
            } catch (error) {
                setLoading(false);
                console.log(error);
                setUserEmailError(error.message);
            }
        }
    };

    return (
        <div>
            <h2 className="text-center my-3 text-gray-800 font-semibold text-xl">Check Mail and Send Reset-Password Mail</h2>
        <div className="w-screen h-[380px] flex flex-col mt-11 items-center">
            
            <form
                onSubmit={verifyUserAndSendMail}
                className="w-full mx-4 md:w-[550px] h-auto py-10 flex flex-col items-center justify-center border shadow-lg rounded-lg"
            >
                <div>
                    <div className="relative">
                        <input
                            type="email"
                            id="email"
                            aria-describedby="email_help"
                            className="block px-2 pb-2.5 pt-4 md:w-[480px] w-full text-sm text-gray-800 bg-transparent rounded-lg border-1 border-blue-600 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            onChange={handleChange}
                            value={email}
                        />
                        <label
                            htmlFor="email"
                            className="absolute text-sm text-blue-800 dark:text-blue-600 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                        >
                            Email
                        </label>
                    </div>

                    {userEmailError && (
                        <p className="mt-1 text-xs text-red-700">
                            {userEmailError}
                        </p>
                    )}

                    {responseMessage && (
                        <div className="flex items-center gap-3 my-auto">
                            {/* Ensure the icon is rendered properly */}
                            <FaCheckCircle className="text-green-500 h-3 w-3" /> {/* Adjust the size for better visibility */}
                            <p className="mt-1 text-xs text-green-500">{responseMessage}</p>
                        </div>
                    )}

                </div>

                <Button
                    className="mt-3"
                    type="submit"
                    gradientDuoTone="cyanToBlue"
                    disabled={loading || isMailSent}
                >
                    {loading
                        ? "Processing..."
                        : isExist
                            ? "Send Mail"
                            : "Verify User"}
                </Button>

                {isMailSent &&
                    <div>
                        <p className="text-xs text-center">Reset Password mail has been sent to your mail address.<br/> Change the password from that link.</p>
                    </div>}
            </form>
        </div>
        </div>
    );
}
