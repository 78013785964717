import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from 'redux-persist';
import storage from "redux-persist/lib/storage";

import { userReducer } from "./user/userSlice.js";
import { preTestReducer } from "./preTest/preTestSlice.js";
import { skillTestReducer } from "./skillTest/skillTestSlice.js";
import { selectSkillReducer } from "./selectSkill/selectSkillSlice.js";
import { adminReducer } from "./adminSlice.js";
import { recruiterReducer } from "./recruiterSlice.js";


const rootReducer = combineReducers({
    user: userReducer,
    preTest: preTestReducer,
    skillTest: skillTestReducer,
    selectSkill: selectSkillReducer,
    admin: adminReducer,
    recruiter: recruiterReducer,
    
})

const persistConfig = {
    key: 'root',
    storage,
    version: 1
}

const persistedReducer = persistReducer(persistConfig, rootReducer);


export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);