

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaUserCircle, FaMapMarkerAlt, FaFilter, FaClipboardList, FaBriefcase, FaSuitcaseRolling, FaSearch, FaCcVisa } from 'react-icons/fa';
import { MdCorporateFare } from "react-icons/md";
import { CgTimelapse } from "react-icons/cg";
import { FaUserGraduate } from 'react-icons/fa6';
import { MdWork } from 'react-icons/md';
import { FaPersonWalkingLuggage } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import { updateRecruiterInfo, updateViewedPofiles } from '../../Redux/recruiterSlice.js';
import { Modal, Button } from 'flowbite-react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';


const majorSkillOptions = [
  { value: "Web Development", label: "Web Development" },
  { value: "Java Developer", label: "Java Developer" },
  { value: "Data Analyst", label: "Data Analyst" },
  { value: "Business Intelligence", label: "Business Intelligence" },
  { value: "Business Analyst", label: "Business Analyst" },
  { value: "Data Science", label: "Data Science" },
  { value: "Cloud Computing", label: "Cloud Computing" },
  { value: "DevOps Engineer", label: "DevOps Engineer" },
  { value: "Mobile App Development", label: "Mobile App Development" },
  { value: "Machine Learning", label: "Machine Learning" },
  { value: "AI/Deep Learning", label: "AI/Deep Learning" },
  { value: "Cybersecurity", label: "Cybersecurity" },
  { value: "UI/UX Design", label: "UI/UX Design" },
  { value: "Full Stack Development", label: "Full Stack Developer" },
  { value: "Software Engineering", label: "Software Engineering" },
  { value: "Project Management", label: "Project Management" },
  { value: "Game Development", label: "Game Development" },
  { value: "Blockchain Developer", label: "Blockchain Developer" },
  { value: "AR/VR Development", label: "AR/VR Development" },
  { value: "Database Administrator", label: "Database Administrator" },
  { value: "Digital Marketing", label: "Digital Marketing" }
];

const RecruiterDashSearch = () => {


  const { currentUser } = useSelector((state) => state.user);
  const { currentRecruiter } = useSelector((state) => state.recruiter);

  const [candidates, setCandidates] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isViewsFinished, setIsViewsFinished] = useState(false);


  const navigate = useNavigate();
  const dispatch = useDispatch();

  // --------------------- Handle View Profile Func ------------------------------------------------------------------
  const handleViewProfile = async (candidate) => {

    if (currentRecruiter.currentPlan.monthlyViews < 1) {
      return setIsViewsFinished(true);
    } else {
      try {
        //-------- pushing the userId in the viewedProfiles array in Recruiter Schema ---
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/recruiter/viewProfile/${currentRecruiter._id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify({ candidateId: candidate._id })
        });

        const data = await res.json();

        if (res.ok) {
          const candidateArr = [candidate];
          dispatch(updateRecruiterInfo(data.recruiterInfo));
          dispatch(updateViewedPofiles(candidateArr));


          //-------- Increase the Recruiter vies count on the userInfo---
          try {
            const viewsRes = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/increaseRecruiterViews/${candidate._id}`, {
              method: 'PUT',
              headers: { 'Content-Type': 'application/json' },
              credentials: 'include',
            });

            if (viewsRes.ok) {
              navigate(`/filter-candidate/profile`, { state: { candidate } });
            }
          } catch (error) {
            console.log(error);
          }


        }
      } catch (error) {
        console.log(error);
      }
    }

  };


  //----------------------------------- Handle View Plans --------------------------------
  const handleViewPlans = () => {
    navigate('/viewPlans');
  }



  const [filters, setFilters] = useState({
    majorSKill: '',
    age: '',
    totalYearsOfExperience: "",
    location: '',
    skills: '',
    visaStatus: '',
    relocate: false,
    isWorking: false,
  });



  const mergeCandidates = async (existingCandidates, newCandidates) => {
    const existingIds = await existingCandidates.map(candidate => candidate.id);
    return [...existingCandidates, ...newCandidates.filter(candidate => !existingIds.includes(candidate.id))];
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Update search term
  };


  //---------------------------- Handle Search Button Fuction -------------------------------
  const handleSearchSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Here you can integrate the logic to fetch candidates based on the search term
    const queryParams = new URLSearchParams({

      searchTerm, // Add search term to the query params
    }).toString();

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/search?${queryParams}`, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.ok) {
        const data = await response.json();

        const viewedProfiles = currentRecruiter.currentPlan.viewedProfile;
        //========filtering viewed candidates before show the search result
        const filteredCandidates = data.filter(candidate => !viewedProfiles.includes(candidate._id));
        setCandidates(filteredCandidates);

      } else {
        console.error('Error fetching candidates:', response.statusText);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    const queryParams = new URLSearchParams({
      ...filters,
    }).toString();

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/filter-candidates?${queryParams}`, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.ok) {
        const data = await response.json();
        const mergedCandidates = await mergeCandidates(candidates, data);

        const viewedProfiles = currentRecruiter.currentPlan.viewedProfile;
        //========filtering viewed candidates before show the search result
        const filteredCandidates = data.filter(candidate => !viewedProfiles.includes(candidate._id));
        setCandidates(filteredCandidates);
      } else {
        console.error('Error fetching candidates:', response.statusText);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  return (
    <div className="flex flex-col w-full bg-gray-100">
      {/* Navbar */}
      <form onSubmit={handleSearchSubmit} className="p-4 flex items-center">
        <input
          type="text"
          placeholder="Search candidates by keywords..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-400 focus:outline-none"
        />
        <button type="submit" className="ml-2 p-2 bg-blue-500 rounded-md text-white hover:bg-blue-600">
          <FaSearch />
        </button>
      </form>

      {/* Filter Section */}
      <aside className="bg-white shadow-lg p-6 border-t border-gray-300">
        <h1 className="text-2xl font-bold mb-4 flex items-center">
          <FaFilter className="mr-2" /> Candidate Filter
        </h1>
        <form onSubmit={handleSubmit} className="flex flex-wrap gap-4">
          <div className="flex-1 min-w-[200px]">
            <label className=" text-gray-700 mb-2 flex items-center text-xs font-semibold">
              <FaUserCircle className="mr-2" /> Major Skill:
            </label>
            <select
              name="majorSKill"
              value={filters.majorSKill}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-400 focus:outline-none text-xs"
            >
              <option value=""><span className='text-sm'>Select Major Skill</span></option>
              {majorSkillOptions.map(option => (
                <option className='text-xs' key={option.value} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          {/* <div className="flex-1 min-w-[200px]">
            <label className="text-gray-700 mb-2 flex items-center text-xs font-semibold">
              <FaClipboardList className="mr-2" /> Min Age:
            </label>
            <input
              type="number"
              name="minAge"
              value={filters.minAge}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-400 focus:outline-none  text-xs"
            />
          </div> */}
          <div className="flex-1 min-w-[200px]">
            <label className="text-gray-700 mb-2 flex items-center text-xs font-semibold">
              <CgTimelapse className="mr-2" /> Age:
            </label>
            <select
              name="age"
              value={filters.age}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-400 focus:outline-none text-xs"
            >
              <option value="">Select age-group</option>
              <option value="18-24">18-24</option>
              <option value="25-30">25-30</option>
              <option value="31-35">31-35</option>
              <option value="36-40">36-40</option>
              <option value="40+">40+</option>
            </select>
          </div>


          <div className="flex-1 min-w-[200px]">
            <label className="text-gray-700 mb-2 flex items-center text-xs font-semibold">
              <MdCorporateFare className="mr-2" /> Total number of experience:
            </label>
            <select
              name="totalYearsOfExperience"
              value={filters.totalYearsOfExperience}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-400 focus:outline-none text-xs"
            >
              <option value="">Select total experience</option>
              <option value="Fresher 0">Less than 1 year(Fresher)</option>
              <option value="1">1 year</option>
              <option value="2">2 years</option>
              <option value="3">3 years</option>
              <option value="4">4 years</option>
              <option value="5">5 years</option>
              <option value="6">6 years</option>
              <option value="7">7 years</option>
              <option value="8">8 years</option>
              <option value="8+">8+ years</option>
            </select>
          </div>

          <div className="flex-1 min-w-[200px]">
            <label className="text-gray-700 mb-2 flex items-center text-xs font-semibold">
              <FaMapMarkerAlt className="mr-2" /> Location:
            </label>
            <input
              type="text"
              name="location"
              value={filters.location}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-400 focus:outline-none text-xs"
            />
          </div>
          <div className="flex-1 min-w-[200px]">
            <label className="text-gray-700 mb-2 flex items-center text-xs font-semibold">
              <FaClipboardList className="mr-2" /> Skills (comma separated):
            </label>
            <input
              type="text"
              name="skills"
              value={filters.skills}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-400 focus:outline-none text-xs"
            />
          </div>
          <div className="flex-1 min-w-[200px]">
            <label className="text-gray-700 mb-2 flex items-center text-xs font-semibold">
              <FaCcVisa className="mr-2" /> Visa Status:
            </label>
            <select
              name="visaStatus"
              value={filters.visaStatus}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-400 focus:outline-none text-xs"
            >
              <option value="">Select Visa Status</option>
              <option value="H-1B">H-1B</option>
              <option value="F-1">F-1</option>
              <option value="J-1">J-1</option>
              <option value="L-1">L-1</option>
              <option value="B-1/B-2">B-1/B-2</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="flex-1 min-w-[200px]">
            <label className="text-gray-700 mb-2 flex items-center text-xs font-semibold">
              <FaPersonWalkingLuggage className="mr-2" /> Relocate:
            </label>
            <select
              name="relocate"
              value={filters.relocate}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-400 focus:outline-none  text-xs"
            >
              <option value="">Select Relocation Status</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
          <div className="flex-1 min-w-[200px]">
            <label className="text-gray-700 mb-2 flex items-center text-xs font-semibold">
              <MdWork className="mr-2" /> Currently Working:
            </label>
            <select
              name="isWorking"
              value={filters.isWorking}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-400 focus:outline-none text-xs"
            >
              <option value="">Select Working Status</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
          <button type="submit" className="mt-4 p-2 bg-blue-500 rounded-md text-white hover:bg-blue-600">
            Apply Filter
          </button>
        </form>
      </aside>

      {/* Candidates List */}
      <main className="flex-grow p-6 overflow-auto">
        {candidates.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {candidates.map((candidate) => (
              <div key={candidate.id} className="bg-white shadow-lg rounded-lg p-6 flex flex-col justify-between">
                <div className="flex flex-col">
                  <h4 className="text-lg font-semibold">{candidate.name}</h4>
                  <p className="text-gray-600 text-sm"><FaBriefcase className="inline-block mr-1" /> Major Skill: <span className='text-xs'>{candidate.majorSKill}</span></p>
                  <p className="text-gray-600 text-sm"><FaMapMarkerAlt className="inline-block mr-1" /> Location: <span className='text-xs'>{candidate.userInfo.location}</span></p>
                  <p className="text-gray-600 text-sm"><FaUserGraduate className="inline-block mr-1" /> Skills: <span className='text-xs'>{candidate.userInfo.skills.join(', ')}</span></p>
                  <p className="text-gray-600 text-sm"><FaSuitcaseRolling className="inline-block mr-1" /> Visa Status: <span className='text-xs'>{candidate.userInfo.visaStatus}</span></p>
                  <p className="text-gray-600 text-sm"><FaSuitcaseRolling className="inline-block mr-1" /> Relocate: <span className='text-xs'>{candidate.userInfo.relocate ? 'Yes' : 'No'}</span></p>
                  <p className="text-gray-600 text-sm"><MdWork className="inline-block mr-1" /> Is Working: <span className='text-xs'>{candidate.userInfo.isWorking ? 'Yes' : 'No'}</span></p>

                </div>
                <button
                  onClick={() => handleViewProfile(candidate)}
                  className="mt-4 bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 flex items-center justify-center"
                >
                  View Profile
                </button>
              </div>
            ))}
          </div>
        ) : (
          <p>No candidates found.</p>
        )}
      </main>
      <Modal show={isViewsFinished}
                onClose={() => setIsViewsFinished(false)}
                popup
                size='md'
            >
                <div className='text-center'>
                    <Modal.Header />
                    <Modal.Body>
                        <HiOutlineExclamationCircle className='h-14 w-14 mx-auto mb-4 text-gray-500 dark:text-gray-300' />
                        <h3 className='mb-3 text-gray-700 text-center text-lg'>Your monthly views limit has been reached.</h3>
                        <p className="mb-3 font-bold">To continue accessing candidates, please upgrade your plan.</p>
                    </Modal.Body>
                    <Modal.Footer className="justify-center">
                        <Button
                            className="bg-sky-600"
                            onClick={handleViewPlans}
                        >
                            View plans
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
    </div>
  );
};

export default RecruiterDashSearch;