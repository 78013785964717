import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    currentRecruiter: null,
    viewedProfiles: [],
    error: null,
    loading: false
}
const recruiterSlice = createSlice({
    name: 'recruiter',
    initialState,
    reducers: {
        updateRecruiterInfo: (state, action) => {
            state.currentRecruiter = action.payload;
            state.error = null;
            state.loading = false;
        },
        removeRecruiterInfo: (state) => {
            state.currentRecruiter = null;
            state.loading = false;
            state.error = null;
        },
        updateViewedPofiles : (state, action) => {
            state.viewedProfiles = [...state.viewedProfiles, ...action.payload];
            state.loading = false;
            state.error = null;
        },
        updateAllViewedProfiles: (state, action) => {
            state.viewedProfiles = [...action.payload];
            state.loading = false;
            state.error = null;
        },
        removeViewedProfiles : (state) => {
            state.viewedProfiles = [];
            state.loading = false;
            state.error = null;
        },
    }
});
export const {
    updateRecruiterInfo,
    removeRecruiterInfo,
    updateViewedPofiles,
    updateAllViewedProfiles,
    removeViewedProfiles
} = recruiterSlice.actions;
export const recruiterReducer = recruiterSlice.reducer;