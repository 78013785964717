import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    preTest: null,
}


const preTestSlice = createSlice({
    name: 'preTest',
    initialState,
    reducers: {

        savePreTestData: (state, action) => {
            state.preTest = action.payload;
        },

        removePreTestData: (state) => {
            state.preTest = null;
        },
    }
});


export const { savePreTestData, removePreTestData } = preTestSlice.actions;

export const preTestReducer = preTestSlice.reducer;