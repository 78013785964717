import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function PrivateRoute() {
    const { currentUser } = useSelector((state) => state.user);
    const location = useLocation();

    // function getAccessTokenCookie() {
    //     const cookieString = document.cookie; // Get all cookies as a single string
    //     const cookies = cookieString.split('; '); // Split into individual key-value pairs
    
    //     // Find the specific 'access_token' cookie
    //     const accessTokenCookie = cookies.find(cookie => cookie.startsWith('access_token='));
    
    //     // If the 'access_token' cookie is found, return its value
    //     if (accessTokenCookie) {
    //         return accessTokenCookie.split('=')[1]; // Return the cookie value
    //     }
    
    //     // If not found, return null
    //     return null;
    // }
    



    // const accessToken = getAccessTokenCookie();
    // console.log(accessToken);

    if (!currentUser) {
        // User is not logged in, redirect to the sign-in page, including the original URL and query string
        return <Navigate to="/signin" state={{ from: location }} replace />;
    }

    // if (currentUser.isAdmin || currentUser.isRecruiter) {
    //     return <Outlet />
    // } else if (currentUser.majorSkill === "") {
    //     // User is logged in but has not selected a skill, redirect to the skill selection page
    //     return <Navigate to="/chooseSkill" state={{ from: location }} replace />;
    // }

    return <Outlet />;
}