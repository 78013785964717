import { div } from '@tensorflow/tfjs';
import React, { useEffect, useState } from 'react';
import {
    FaUser,
    FaEnvelope,
    FaBuilding,
    FaPhone,
    FaMapMarkerAlt,
    FaClipboardList,
    FaClock,
    FaBriefcase,
    FaSearch, FaPhoneAlt
} from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { updateCurrentUser } from '../../Redux/user/userSlice';
import { updateRecruiterInfo } from '../../Redux/recruiterSlice';
import { useNavbarVisibility } from '../NavbarVisibilityContext.jsx';
import { useFooterVisibility } from '../FooterVisibilityContext.jsx';

// Example country code data with flags (you can add more)
const countryCodes = [
    { code: '+1', country: 'United States', flag: '🇺🇸' },
    { code: '+91', country: 'India', flag: '🇮🇳' },
    { code: '+44', country: 'United Kingdom', flag: '🇬🇧' },
    { code: '+61', country: 'Australia', flag: '🇦🇺' },
    { code: '+81', country: 'Japan', flag: '🇯🇵' },
];

const RecruiterForm = () => {

    const { setIsNavbarVisible } = useNavbarVisibility();
    const { setIsFooterVisible } = useFooterVisibility();

    const { currentUser } = useSelector((state) => state.user);
    const { currentRecruiter } = useSelector((state) => state.recruiter);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        userId: currentUser._id,
        name: '',
        email: '',
        company: '',
        post: '',
        contactNumber: '',
        countryCode: '+1',
        address: { city: '', state: '', country: '' },
        source: '',
        numberOfPosition: '',
        time: ''
    });

    // remove Navbar and Footer 
    useEffect(() => {
        setIsFooterVisible(false);
        setIsNavbarVisible(false);
    }, [])


    // Handle form changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name.includes('address')) {
            const addressField = name.split('.')[1];
            setFormData((prevData) => ({
                ...prevData,
                address: { ...prevData.address, [addressField]: value }
            }));
        } else {
            setFormData((prevData) => ({ ...prevData, [name]: value }));
        }
    };

    // Handle country code change
    const handleCountryCodeChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            countryCode: e.target.value
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        const fullPhoneNumber = `${formData.countryCode} ${formData.contactNumber}`;

        try {
            // Construct the URL for the updateRecruiter API
            setLoading(true);
            const recruiterUrl = `${process.env.REACT_APP_BACKEND}/api/recruiter/upadteRecruiter?id=${currentRecruiter?._id ? currentRecruiter._id : ''}`;

            // Update the RecruiterInfo in the Recruiter collection
            const recruiterRes = await fetch(recruiterUrl, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                credentials: 'include',
                body: JSON.stringify(formData)
            });

            const recruiterData = await recruiterRes.json();

            if (recruiterRes.ok) {
                const recruiterInfoId = recruiterData.user._id;
                dispatch(updateRecruiterInfo(recruiterData.user)); // Update recruiter info in the state

                try {
                    // Update the recruiterInfoId in the USER schema
                    const userInfoUrl = `${process.env.REACT_APP_BACKEND}/api/user/updateRecruiterInfoId/${currentUser._id}`;

                    const userInfoRes = await fetch(userInfoUrl, {
                        method: 'PUT',
                        headers: { "Content-Type": "application/json" },
                        credentials: 'include',
                        body: JSON.stringify({ recruiterInfoId })
                    });

                    const updatedUserData = await userInfoRes.json();

                    if (userInfoRes.ok) {
                        dispatch(updateCurrentUser(updatedUserData.user)); // Update user info in the state
                        setLoading(false);
                        setIsFooterVisible(true);
                        setIsNavbarVisible(true);
                        navigate('/viewPlans'); // Navigate to viewPlans after successful update
                    } else {
                        console.error('Failed to update user recruiterInfoId:', updatedUserData.message);
                    }
                } catch (userUpdateError) {
                    console.error('Error updating recruiterInfoId in User:', userUpdateError);
                }
            } else {
                console.error('Failed to update recruiter:', recruiterData.message);
            }
        } catch (error) {
            console.error('Error during recruiter update:', error);
        }

        console.log('Full Phone Number:', fullPhoneNumber);
        console.log('Form Data:', formData);
    };


    return (

        <div className="min-h-screen flex flex-col justify-between">



            {/* Form Section */}
            {currentUser.isRecruiter !== true ? (
                <div>You are not allowed to view this page. Go back.</div>
            ) : (
                <div className="flex-grow flex justify-center items-center bg-gray-100">
                    <form
                        onSubmit={handleSubmit}
                        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg my-5"
                    >
                        <h2 className="text-3xl font-bold text-center mb-6 text-gray-800 tracking-wide">
                            Recruiter Information
                        </h2>

                        {/* Name Field */}
                        <div className="flex flex-col mb-4">
                            <label className="flex items-center text-gray-700">
                                <FaUser className="mr-2 text-gray-500" />
                                Recruiter's Name <span className="text-red-500 ml-1">*</span>
                            </label>
                            <small className="text-gray-500 mt-1">This is the full name of the recruiter.</small>
                            <input
                                type="text"
                                name="name"
                                placeholder="Enter recruiter's name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                            />

                        </div>

                        {/* Email Field */}
                        <div className="flex flex-col mb-4">
                            <label className="flex items-center text-gray-700">
                                <FaEnvelope className="mr-2 text-gray-500" />
                                Recruiter's Email <span className="text-red-500 ml-1">*</span>
                            </label>
                            <small className="text-gray-500 mt-1">The email address should be unique and valid.</small>
                            <input
                                type="email"
                                name="email"
                                placeholder="Enter recruiter's email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                            />

                        </div>

                        {/* Company Field */}
                        <div className="flex flex-col mb-4">
                            <label className="flex items-center text-gray-700">
                                <FaBuilding className="mr-2 text-gray-500" />
                                Company Name <span className="text-red-500 ml-1">*</span>
                            </label>
                            <small className="text-gray-500 mt-1">Provide the full name of the company.</small>
                            <input
                                type="text"
                                name="company"
                                placeholder="Enter company name"
                                value={formData.company}
                                onChange={handleChange}
                                required
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                            />

                        </div>

                        {/* Post Field */}
                        <div className="flex flex-col mb-4">
                            <label className="flex items-center text-gray-700">
                                <FaBriefcase className="mr-2 text-gray-500" />
                                Recruiter's Post <span className="text-red-500 ml-1">*</span>
                            </label>
                            <small className="text-gray-500 mt-1">Job title or role of the recruiter.</small>
                            <input
                                type="text"
                                name="post"
                                placeholder="Enter recruiter's post"
                                value={formData.post}
                                onChange={handleChange}
                                required
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                            />

                        </div>

                        {/* Contact Number Field with Country Code */}
                        <div className="flex flex-col mb-4">
                            <label className="flex items-center text-gray-700">
                                <FaPhoneAlt className="mr-2 text-gray-500" />
                                Contact Number <span className="text-red-500 ml-1">*</span>
                            </label>
                            <small className="text-gray-500 mt-1">A valid phone number with country code.</small>
                            <div className="flex">
                                <select
                                    name="countryCode"
                                    value={formData.countryCode}
                                    onChange={handleCountryCodeChange}
                                    className="mr-2 px-4 py-2 border rounded-lg focus:outline-none"
                                >
                                    {countryCodes.map((country) => (
                                        <option key={country.code} value={country.code}>
                                            {country.flag} {country.code}
                                        </option>
                                    ))}
                                </select>
                                <input
                                    type="text"
                                    name="contactNumber"
                                    placeholder="Enter contact number"
                                    value={formData.contactNumber}
                                    onChange={handleChange}
                                    required
                                    className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                                />
                            </div>

                        </div>

                        {/* Address Fields */}
                        <div className="flex flex-col mb-4">
                            <label className="flex items-center text-gray-700">

                                <FaMapMarkerAlt className="mr-2 text-gray-500" />
                                Address
                            </label>
                            <small className="text-gray-500 mt-1">Optional: provide city, state, and country.</small>
                            <input
                                type="text"
                                name="city"
                                placeholder="City"
                                value={formData.address.city}
                                onChange={handleChange}
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none mt-2"
                            />
                            <input
                                type="text"
                                name="state"
                                placeholder="State"
                                value={formData.address.state}
                                onChange={handleChange}
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none mt-2"
                            />
                            <input
                                type="text"
                                name="country"
                                placeholder="Country"
                                value={formData.address.country}
                                onChange={handleChange}
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none mt-2"
                            />

                        </div>

                        {/* Source */}
                        <div className="flex flex-col mb-4">
                            <label className="flex items-center text-gray-700">
                                <FaSearch className="mr-2 text-gray-500" />
                                Source
                            </label>
                            <small className="text-gray-500 mt-1">Where did you hear about us?</small>
                            <input
                                type="text"
                                name="source"
                                placeholder="Enter source"
                                value={formData.source}
                                onChange={handleChange}
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                            />

                        </div>

                        {/* Number of Positions */}
                        <div className="flex flex-col mb-4">
                            <label className="flex items-center text-gray-700">
                                <FaClipboardList className="mr-2 text-gray-500" />
                                Number of Positions you want to hire
                            </label>
                            <input
                                type="number"
                                name="numberOfPosition"
                                placeholder="Enter the number of positions"
                                value={formData.numberOfPosition}
                                onChange={handleChange}
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                            />
                        </div>

                        {/* Submit Button */}
                        <button
                            type="submit"
                            className="bg-blue-600 text-white py-2 px-6 rounded-lg font-semibold w-full"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            {loading ? 'Loading...' : 'Submit'}
                        </button>
                    </form>
                </div>)}

            {/* Footer */}

        </div>
    );
};

export default RecruiterForm;
