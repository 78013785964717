import React from 'react';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';


const DashProfile = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [profileCompletion, setProfileCompletion] = useState(0); // Store profile completion percentage

  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate(); // Initialize navigate



  const handleNavigate = () => {
    navigate('/userForm'); // This will navigate to /dashboard
  };



  // useEffect(  async() => {
  //     const fetchUserData = async () => {
  //       try {
  //         const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/userId/${currentUser._id}`, {
  //           method: "GET",
  //           credentials: "include", // Include cookies if needed
  //           headers: { "Content-Type": "application/json" },
  //         });

  //         if (res.ok) {
  //           // Parse the response body as JSON
  //           const data = await res.json();
  //           setUserData(data)
  //           console.log("User data:", data); // Log the data or use it as needed

  //           // You can update the state to store the response data
  //           // setUserData(data); // Example of setting data in state
  //         } else {
  //           // Handle error response (e.g., 404, 500)
  //           console.error("Failed to fetch user data:", res.status);
  //         }
  //       } catch (error) {
  //         // Handle network or other errors
  //         console.error("Error fetching user data:", error);
  //       }
  //     };

  //     await fetchUserData();
  //   }, []); // Run the effect when the user ID changes



  useEffect(() => {
    const fetchUserData = async () => {
      if (!currentUser || !currentUser._id) {
        setError("User ID not available");
        setLoading(false);
        return;
      }

      try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/userId/${currentUser._id}`, {
          method: "GET",
          credentials: "include", // Include cookies if needed
          headers: { "Content-Type": "application/json" },
        });

        if (res.ok) {
          const data = await res.json();

          // Optional: Validate if the necessary properties exist in the data
          if (data && typeof data === 'object') {
            setUserData(data.user); // Set the user data
            calculateProfileCompletion(data.user); // Calculate profile completion


          } else {
            setError("Invalid user data received");
          }
        } else {
          setError(`Failed to fetch user data: ${res.status} ${res.statusText}`);
        }
      } catch (error) {
        setError(`Error fetching user data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();

  }, [currentUser]); // Run the effect when the user ID changes


  const calculateProfileCompletion = (data) => {
    let totalFields = 0;
    let filledFields = 0;

    // Define the fields to check
    const fieldsToCheck = [
      { field: data.name, isValid: (value) => value && value.trim() !== "" },
      { field: data.majorSKill, isValid: (value) => value && value.trim() !== "" },
      { field: data.isRecruiter, isValid: (value) => value !== undefined },
      { field: data.userInfo?.bio, isValid: (value) => value && value.trim() !== "" },
      { field: data.userInfo?.age, isValid: (value) => value && value !== "" },
      { field: data.userInfo?.location, isValid: (value) => value && value.trim() !== "" },
      { field: data.userInfo?.visaStatus, isValid: (value) => value && value.trim() !== "" },
      { field: data.userInfo?.mostRecentDegree?.collageName, isValid: (value) => value && value.trim() !== "" },
      { field: data.userInfo?.mostRecentDegree?.degreeName, isValid: (value) => value && value.trim() !== "" },
      { field: data.userInfo?.relocate, isValid: (value) => value !== undefined },
      { field: data.userInfo?.experience?.length, isValid: (value) => value > 0 },
      { field: data.userInfo?.skills?.length, isValid: (value) => value > 0 },
    ];

    // Calculate total and filled fields
    fieldsToCheck.forEach(({ field, isValid }) => {
      totalFields++;
      if (isValid(field)) {
        filledFields++;
      }
    });

    // Calculate completion percentage
    const completionPercentage = Math.round((filledFields / totalFields) * 100);
    setProfileCompletion(completionPercentage); // Update the state with the completion percentage
  };

  const profileData = {


    searchAppearances: 42,
    recruiterActions: 11,
    profileCompletion: 63,
    missingDetails: 7,
    basicDetails: {
      gender: 'Male',
      location: 'Kolkata',
      birthdate: '30th October 2003',
      email: 'johndoe@example.com',
      phone: '1234567890',
    },
    careerPreferences: {
      jobType: 'Add desired job type',
      availability: 'Add work availability',
      location: 'Kolkata',
    },
    Skills: [
      { level: 'Hard', name: 'Java' },
      { level: 'Hard', name: 'HTML' },
      { level: 'Hard', name: 'CSS' },
      { level: 'Hard', name: 'Javascript' },
    ]
  };

  if (loading) {
    return (<div className="flex items-center justify-center h-screen"><ClipLoader loading={loading} size={50} color="blue" /></div>)
  };
  if (error) return <div>Error: {error}</div>;



  return (
    <div className="profile-page p-6 bg-gradient-to-b from-blue-50 via-white to-blue-50 min-h-screen">
      {/* Header */}
      <div className="header flex items-center justify-between space-x-4 bg-gradient-to-r from-blue-500 to-blue-700 text-white p-6 rounded-lg shadow-lg">
        <div className='max-w-[550px] flex flex-row items-center'>
          <img
            src={currentUser.profilePicture && currentUser.profilePicture}
            alt="Profile"
            className="profile-picture m-8 rounded-full border-4 border-white min-w-24 min-h-24 object-cover"
          />
          <div className="profile-info flex-grow">
            <h2 className="text-2xl font-bold">{userData.name}</h2>
            <p className="text-xs font-light text-gray-200">{userData.userInfo?.bio}</p>
          </div>
        </div>
        <div className="metrics flex space-x-8">
          <div className="text-center">
            <div className="text-2xl font-semibold">{profileData.searchAppearances}</div>
            <div className="font-light text-xs">Number of Tests Taken</div>
          </div>
          <div className="text-center">
            <div className="text-2xl font-semibold">{profileData.recruiterActions}</div>
            <div className="text-xs font-light">Recruiter Actions</div>
          </div>
        </div>
        <div>
          <button
            onClick={handleNavigate}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4 "
          >
            EDIT
          </button>
        </div>
      </div>
      {/* Profile Completion */}
      <div>


      </div>
      {profileCompletion === 100 ? (<div className="profile-completion mt-6 p-6  rounded-lg shadow-lg flex items-center justify-between text-gray-700">
        <div>
          <h3 className="text-xl font-semibold">You Have A Complete Profile</h3>
        </div>
        <div className="text-center">
          <div className="text-green-500 text-3xl  font-bold">{profileCompletion}%</div>
          <div className="text-gray-500 text-sm">Profile Completion</div>
        </div>
        <div className="text-green-500 text-sm font-semibold ">
          {Math.round(((100 - profileCompletion) / 100) * 12)} missing details
        </div>
      </div>) : (<div className="profile-completion mt-6 p-6 bg-white rounded-lg shadow-lg flex items-center justify-between text-gray-700">
        <div>
          <h3 className="text-xl font-semibold">Complete your profile</h3>
          <p className="text-sm font-light">Benda infotech experts suggest you to have a complete profile</p>
        </div>
        <div className="text-center">
          <div className={`text-${profileCompletion > 50 ? "yellow" : "red"}-500 text-3xl font-bold`}>{profileCompletion}%</div>
          <div className="text-gray-500 text-sm">Profile Completion</div>
        </div>
        <div className={`text-${profileCompletion > 50 ? "yellow" : "red"}-500 text-sm font-semi-bold`}>
          {Math.round(((100 - profileCompletion) / 100) * 12)} missing details
        </div>
      </div>)}
      {/* Basic Details */}
      <div className="basic-details mt-6 p-6 bg-white rounded-lg shadow-lg">
        <h3 className="text-xl font-semibold text-gray-800">Basic Details</h3>
        <div className="mt-4 space-y-2 text-gray-600">
          <p><strong>Email:</strong> {userData.email}</p>
          <p><strong>Age:</strong> {userData.userInfo?.age}</p>
          <p><strong>Major Skill:</strong> {userData.majorSKill}</p>
          <p><strong>Phone No:</strong> {userData.userInfo?.phoneNumber}</p>
          <p><strong>Total years of experiences:</strong> {userData.userInfo?.totalYearsOfExperience === 'Fresher 0' ? 'Fresher' : userData.userInfo?.totalYearsOfExperience}</p>
          <p><strong>Location:</strong> {userData.userInfo?.location}</p>
          <p><strong>Visa Status:</strong> {userData.userInfo?.visaStatus}</p>
        </div>
      </div>
      {/* Career Preferences */}
      <div className="career-preferences mt-6 p-6 bg-white rounded-lg shadow-lg">
        <h3 className="text-xl font-semibold text-gray-800">Your Career Preferences</h3>
        <div className="mt-4 space-y-2 text-gray-600">
          <p><strong>Job Type:</strong> {userData.majorSKill || 'Not specified'}</p>
          <p><strong>Willing to Relocate:</strong> {userData.userInfo?.relocate ? 'Yes' : 'No'}</p>
          <p><strong>Preferred Locations:</strong>   <ul>
            {userData.userInfo?.relocateLocations.map((location, index) => (
              <li key={index}>{location}</li>
            ))}
          </ul></p>



        </div>
      </div>
      {/* Education */}
      <div className="basic-details mt-6 p-6 bg-white rounded-lg shadow-lg">
        <h3 className="text-xl font-semibold text-gray-800">Recent Education</h3>
        <div className="mt-4 space-y-2 text-gray-600">
          <p><strong>College Name:</strong> {userData.userInfo?.mostRecentDegree?.collageName || 'Not specified'}</p>
          <p><strong>Degree:</strong> {userData.userInfo?.mostRecentDegree?.degreeName || 'Not specified'}</p>
          <p><strong>Start Year:</strong>
            {userData.userInfo?.mostRecentDegree?.startYear
              ? new Date(userData.userInfo.mostRecentDegree.startYear).getFullYear()
              : 'Not specified'}</p>
          <p><strong>End Year:</strong>
            {userData.userInfo?.mostRecentDegree?.endYear
              ? new Date(userData.userInfo.mostRecentDegree.endYear).getFullYear()
              : 'Not specified'}
          </p>
        </div>
      </div>



      {/*Experience*/}
      <div className="basic-details mt-6 p-6 bg-white rounded-lg shadow-lg">
        <h3 className="text-xl font-semibold text-gray-800">Experieneces</h3>
        <div className="mt-4 space-y-2 text-gray-600">
          {userData.userInfo?.experience.map((exp, index) => (
            <div key={index}>
              <h6 className="text-sm font-semibold text-gray-800">Experienece-{index + 1}</h6>



              <p><strong>Domain:</strong> {exp.domain}</p>
              <p><strong>Years of Experience:</strong> {exp.yearsOfExperience}</p>
            </div>
          ))}
        </div>
      </div>
      {/*COURSES*/}
      <div className="basic-details mt-6 p-6 bg-white rounded-lg shadow-lg">
        <h3 className="text-xl font-semibold text-gray-800">Courses attended</h3>
        <div className="mt-4 space-y-2 text-gray-600">
          <ul>
            {userData.userInfo?.courses.map((course, index) => (
              <li key={index}>{course}</li>
            ))}
          </ul>
        </div>
      </div>
      {/*WORKING STATUS*/}



      <div className="basic-details mt-6 p-6 bg-white rounded-lg shadow-lg">
        <h3 className="text-xl font-semibold text-gray-800">Working status:</h3>
        <div className="mt-4 space-y-2 text-gray-600">
          <p><strong>Currently Working:</strong> {userData.userInfo?.isWorking ? 'Yes' : 'No'}</p>

        </div>
      </div>


      {/*SKILLS*/}
      <div className="education mt-6 p-6 bg-white rounded-lg shadow-lg">
        <h3 className="text-xl font-semibold text-gray-800">Other Skills:</h3>
        <div className=" flex flex-row space-x-4 text-gray-600">
          <ul>
            {userData.userInfo?.skills.map((skill, index) => (
              <li key={index}>{skill}</li>
            ))}
          </ul>
        </div>

      </div>

      <div className='flex flex-row align-middle justify-center'>

      </div>

    </div>
  );
};
export default DashProfile;