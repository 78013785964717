
import { useEffect, useState } from "react";
import { FaUserTie } from "react-icons/fa";
import { MdOutlineEmail, MdBusiness, MdPhone, MdLocationOn, MdDateRange, MdWork } from "react-icons/md";
import { FaArrowRightToBracket } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import { updateAllViewedProfiles, updateRecruiterInfo } from "../../Redux/recruiterSlice";
import { Card } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';


const RecruiterDashProfile = () => {

  const { currentUser } = useSelector((state) => state.user);
  const { currentRecruiter } = useSelector((state) => state.recruiter);

  const [recruiterInfo, setRecruiterInfo] = useState({});
  const [lastViewedProfiles, setLastViewedProfiles] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();




  const fetchRecruiterInfo = async () => {
    if (currentUser && currentRecruiter) {
      try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/recruiter/getRecruiter/${currentRecruiter._id}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });

        const data = await res.json();
        if (res.ok) {
          setRecruiterInfo(data.user);
          dispatch(updateRecruiterInfo(data.user));
          dispatch(updateAllViewedProfiles(data.viewedProfiles));
          const lastFiveIds = data.viewedProfiles.slice(-5);
          setLastViewedProfiles([...lastFiveIds]);
        } else {
          console.error("Failed to fetch recruiter:", data.message);
        }
      } catch (err) {
        console.error("Error fetching recruiter info:", err);
      }
    }
  };

  //------------------ handle go to profile ---------------------
  const handleViewProfile = (candidate) => {
    navigate(`/filter-candidate/profile`, { state: { candidate } });
  }


  useEffect(() => {
    if (currentUser && currentRecruiter) {
      fetchRecruiterInfo();
    }
  }, []);


  return (
    <div className="  overflow-hidden m-2">
      <div>
        {/* Profile Header */}
        <div className="flex justify-between items-center bg-gradient-to-r from-blue-500 to-indigo-600 text-white p-6 rounded-lg my-2">
          <div className="flex items-center">
            <FaUserTie size={80} className="mr-4" />
            <div>
              <h1 className="text-3xl font-bold">{currentRecruiter.name}</h1>
              <p className="mt-2 text-sm">{currentRecruiter.post}</p>
            </div>
          </div>
          <div className="flex space-x-8">
            <div className="text-center">
              <h2 className="text-4xl font-semibold">{currentRecruiter.currentPlan.monthlyViews}</h2>
              <p className="text-sm">Profile views left</p>
            </div>
            <div className="text-center">
              <h2 className="text-4xl font-semibold uppercase">{currentRecruiter.currentPlan.planType}</h2>
              <p className="text-sm">Plan</p>
            </div>
            <div className="text-center">
              <h2 className="text-4xl font-semibold">{new Date(currentRecruiter.currentPlan.expiredOn).toLocaleDateString()}</h2>
              <p className="text-sm">Plan Expires on</p>
            </div>
          </div>
        </div>

        {/* Recruiter Profile Details */}
        <div className="p-6 bg-gray-100 shadow-md rounded-lg my-2">
          <h4 className="font-semibold text-gray-700 text-lg mb-2">Basic Details</h4>
          <div className="grid grid-cols-2 gap-4">

            {/* Email */}
            <div className="flex items-center">
              <MdOutlineEmail className="text-blue-500 mr-2" size={24} />
              <span className="text-sm text-gray-800">{currentRecruiter.email}</span>
            </div>

            {/* Company */}
            <div className="flex items-center">
              <MdBusiness className="text-blue-500 mr-2" size={24} />
              <span className="text-sm text-gray-800">{currentRecruiter.company}</span>
            </div>

            {/* Post */}
            <div className="flex items-center">
              <MdWork className="text-blue-500 mr-2" size={24} />
              <span className="text-sm text-gray-800">{currentRecruiter.post}</span>
            </div>

            {/* Contact Number */}
            <div className="flex items-center">
              <MdPhone className="text-blue-500 mr-2" size={24} />
              <span className="text-sm text-gray-800">{currentRecruiter.contactNumber}</span>
            </div>

            {/* Address */}
            <div className="flex items-center">
              <MdLocationOn className="text-blue-500 mr-2" size={24} />
              <span className="text-sm text-gray-800">{`${currentRecruiter.address.city}, ${currentRecruiter.address.state}, ${currentRecruiter.address.country}`}</span>
            </div>

            {/* Plan Expiry */}
            <div className="flex items-center">
              <MdDateRange className="text-blue-500 mr-2" size={24} />
              <span className="text-sm text-gray-800">Plan expires on: {new Date(currentRecruiter.currentPlan.expiredOn).toLocaleDateString()}</span>
            </div>
          </div>
        </div>
      </div>


      <div>
        <Card className="max-w-sm">
          <div className="mb-4 flex items-center justify-between">
            <h5 className="text-xl font-bold leading-none text-gray-700 ">Viewed Profiles</h5>
            <a href="/dashboard?tab=viewedCandidates" className="text-sm font-medium text-cyan-600 hover:underline ">
              View all
            </a>
          </div>
          <div className="flow-root">
            <ul className="divide-y divide-gray-200 ">
              {lastViewedProfiles.map((candidate, index) => (
                <li key={index} className="py-3 sm:py-4">
                  <div className="flex items-center space-x-4">
                    <div className="shrink-0">
                      <img
                        alt="Candidate Picrure"
                        height="32"
                        src={candidate.profilePicture}
                        width="32"
                        className="rounded-full"
                      />
                    </div>
                    <div className="min-w-0 flex-1">
                      <p className="truncate text-sm font-medium text-gray-900 ">{candidate.name}</p>
                      <p className="truncate text-xs text-gray-500 ">{candidate.email}</p>
                    </div>
                    <div className="bg-sky-600 rounded-full hover:bg-sky-800" onClick={()=>handleViewProfile(candidate)}>
                      <FaArrowRightToBracket className="p-3 text-white" size={40} />
                    </div>

                  </div>
                </li>
              ))}
            </ul>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default RecruiterDashProfile;
