import 'animate.css'
import { Button } from 'flowbite-react'
import { Link } from 'react-router-dom'
export default function SkillTestInstruction() {

    return (
        <div className="flex flex-col items-center">

            <h2 className=' mt-4 bg-blue-600 border-2 border-blue-800 px-6 py-2 rounded-tl-3xl rounded-br-3xl text-xl shadow-lg text-gray-100 mb-6 '>
                Detailed Instruction for the Test
            </h2>

            <div className="my-2 mx-2 md:m-6 md:p-10 border shadow-lg rounded-md flex flex-col md:flex-row gap-2 md:gap-8 bg-gradient-to-r from-sky-300 to-sky-100">
                <div className="w-full md:w-1/2 p-2 border shadow-sm ">
                    <img alt="step-1" src="step1.png" />
                </div>
                <div className="w-full py-3 px-2 md:w-1/2 flex flex-col items-center md:items-start justify-center">
                    <h2 className="text-xl">Step.<span className="text-3xl">1</span></h2>
                    <h2 className="text-lg text-gray-800 font-bold">Choose Your Test Category:</h2>
                    <p className="ml-4 w-full md:w-3/4 text-xs animate__animated animate__lightSpeedInLeft">To start a test, hover over the relevant card and click "Take the test" below the description. For example, to assess your Data Analysis skills, click the button on the Data Analyst card.</p>
                </div>
            </div>


            <div className="my-2 mx-2 md:m-6 md:p-10 border shadow-lg rounded-md flex flex-col md:flex-row-reverse  gap-2 md:gap-8 bg-gradient-to-r from-sky-100 to-sky-300">
                <div className="w-full md:w-1/2 p-2 border shadow-sm ">
                    <img alt="step-2" src="step2.png" />
                </div>
                <div className="w-full py-3 px-2 md:w-1/2 flex flex-col items-center md:items-start justify-center">
                    <h2 className="text-xl">Step.<span className="text-3xl">2</span></h2>
                    <h2 className="text-lg text-gray-800 font-bold">Select Test Difficulty Level:</h2>
                    <p className="ml-4 w-full md:w-3/4 text-xs animate__animated animate__lightSpeedInRight">
                        <ul className='list-disc'>
                            <li>After choosing a category, you'll see test sets tailored to different skill levels: Easy, Medium, and Hard.</li>
                            <li> Each difficulty card provides information about
                                prerequisites, time duration, marks, and question types (e.g.,
                                coding problems and MCQs).</li>
                            <li>Once ready, hover over and click the "Take test" button for
                                your chosen difficulty level.</li>
                        </ul>
                    </p>
                </div>
            </div>


            <div className="my-2 mx-2 md:m-6 md:p-10 border shadow-lg rounded-md flex flex-col md:flex-row gap-2 md:gap-8 bg-gradient-to-r from-sky-300 to-sky-100">
                <div className="w-full md:w-1/2 p-2 border shadow-sm ">
                    <img alt="step-3" src="step3.png" />
                </div>
                <div className="w-full py-3 px-2 md:w-1/2 flex flex-col items-center md:items-start justify-center">
                    <h2 className="text-xl">Step.<span className="text-3xl">3</span></h2>
                    <h2 className="text-lg text-gray-800 font-bold">Go through the instructions once.</h2>
                    <p className="ml-4 w-full md:w-3/4 text-xs animate__animated animate__lightSpeedInLeft">Before proceding further for the test, go through the instructions thoroughly.</p>
                </div>
            </div>

            <div className="my-2 mx-2 md:m-6 md:p-10 border shadow-lg rounded-md flex flex-col md:flex-row-reverse gap-2 md:gap-8 bg-gradient-to-r from-sky-100 to-sky-300">
                <div className="w-full md:w-1/2 p-2 border shadow-sm ">
                    <img alt="step-4" src="step4.png" />
                </div>
                <div className="w-full py-3 px-2 md:w-1/2 flex flex-col items-center md:items-start justify-center">
                    <h2 className="text-xl">Step.<span className="text-3xl">4</span></h2>
                    <h2 className="text-lg text-gray-800 font-bold">Grant Permissions:</h2>
                    <p className="ml-4 w-full md:w-3/4 text-xs animate__animated animate__lightSpeedInRight">
                        <ul className='list-disc'>
                            <li>Click the "Allow" button to give access and proceed with
                                the test. This step is mandatory for monitoring purposes.</li>
                            <li> Allow Camera & Microphone Access: A dialog box will
                                appear requesting permission to access your camera
                                and microphone.</li>
                            <li>Start the test with “Take Test” Button</li>
                        </ul>
                    </p>
                </div>
            </div>


            <div className='w-screen flex flex-col items-center justify-center'>

                <div className="w-full py-3 px-2 flex flex-col items-center justify-center">
                    <h2 className="text-xl">Step.<span className="text-3xl">5</span></h2>
                    <h2 className="text-lg text-gray-800 font-bold">SKILL TEST PAGE</h2>
                </div>

                <div className="my-2 mx-2 md:m-6 md:p-4 border shadow-lg rounded-md flex flex-col md:flex-row gap-2 md:gap-8 bg-gradient-to-r from-sky-300 to-sky-100">

                    <div className="w-full md:w-1/2 p-2 border shadow-sm ">
                        <p className='text-sm mx-auto text-center my-2 underline'>Selected question is a MCQ</p>
                        <img alt="MCQ" src="stepMCQ.png" />

                        <div className='py-3 flex flex-col md:flex-row justify-between items-center border-b border-black'>
                            <img className='h-60 w-60' src='mcqQuestion.png' />
                            <div>
                                <h2 className='text-sm font-bold'>Question area:</h2>
                                <p className='text-xs'>Qestion area for selected MCQ question.</p>
                            </div>
                        </div>

                        <div className='py-3 flex flex-col md:flex-row justify-between items-center border-b border-black'>
                            <img className='h-60 w-60' src='mcqArea.png' />
                            <div>
                                <h2 className='text-sm font-bold'>MCQ area:</h2>
                                <p className='text-xs'>Question area for selected MCQ question.<br /><br />
                                    Select an option.
                                </p>
                            </div>
                        </div>

                    </div>



                    <div className="w-full md:w-1/2 p-2 border shadow-sm ">
                        <p className='text-sm mx-auto text-center my-2 underline'>Selected question is a CODING problem</p>
                        <img alt="code" src="stepCode.png" />

                        <div className='py-3 flex flex-col md:flex-row justify-between items-center border-b border-black'>
                            <img className='h-60 w-60' src='codeQuestion.png' />
                            <div className='p-2'>
                                <h2 className='text-sm font-bold'>Question area:</h2>
                                <p className='text-xs'>Qestion and instruction area for coding question.<br /> Also shown what the ouptput should be.</p>
                            </div>
                        </div>

                        <div className='py-3 flex flex-col md:flex-row justify-between items-center border-b border-black'>
                            <img className='h-60 w-60' src='codeArea.png' />
                            <div className='p-2'>
                                <h2 className='text-sm font-bold'>Coding IDE area:</h2>
                                <p className='text-xs'>Write your code here.<br /> Do not change the predefine code.</p>
                            </div>
                        </div>
                    </div>



                </div>

                <div className='py-3 flex w-4/5 flex-col md:flex-row justify-start items-center border-b border-black'>
                    <img className='h-60 border border-gray-500' src='output.png' />
                    <div className='p-2'>
                        <h2 className='text-sm font-bold'>Output Area:</h2>
                        <p className='text-xs'>After write your code hit the "Compile" button to show the output.</p><br />
                        <p className='text-xs'>If you changed the predefined code, then hit the "Reset" button.</p>
                    </div>
                </div>

                <div className='py-3 flex w-4/5 flex-col md:flex-row justify-start items-center border-b border-black'>
                    <img className='w-80 border border-gray-500' src='navigateButtons.png' />
                    <div className='p-2'>
                        <h2 className='text-sm font-bold'>Navigate buttons:</h2>
                        <p className='text-xs'>Click this buttons for navigateto the previous or next question.</p>
                    </div>
                </div>

                <div className='py-3 flex w-4/5 flex-col md:flex-row justify-start items-center border-b border-black'>
                    <img className='h-60 border border-gray-500' src='saveAndNext.png' />
                    <div className='p-2'>
                        <h2 className='text-sm font-bold'>Save & Next button:</h2>
                        <p className='text-xs'>After write your code or after selecting any MCQ option hit "Save & Next" button to save your response and move to the next question.</p>
                    </div>
                </div>

                <div className='py-3 flex w-4/5 flex-col md:flex-row justify-start items-center border-b border-black'>
                    <img className='w-80 border border-gray-500' src='submitTest.png' />
                    <div className='p-2'>
                        <h2 className='text-sm font-bold'>Submit & End Test</h2>
                        <p className='text-xs'>After attempting all the questions hit "Submit & End Test" button to submit your skill text and end the test.</p>
                    </div>
                </div>

            </div>




            <div className='mt-6'>
                <Link to='/selectSkill' className=" text-gray-200 hover:text-white px-4 py-2 bg-sky-700 hover:bg-sky-600 rounded-full">Select your skill</Link>
            </div>

        </div>
    )
}