import { useSelector, useDispatch } from 'react-redux';
import { setSelectedSkill } from '../Redux/selectSkill/selectSkillSlice.js';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import Lottie from "lottie-react";
import { Button } from "flowbite-react";
import java from "../assets/java.json"
import python from "../assets/python.json"
import c from "../assets/c.json"
import dataAnalyst from '../assets/dataAnalyst.json';
import businessAnalyst from '../assets/businessAnalyst.json';
import businessIntelligence from '../assets/businessIntelligence.json'
import financialAnalyst from '../assets/financialAnalyst.json';


export default function SelectSkill() {


    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { currentUser } = useSelector((state) => state.user);

    const handleInstruction = () => {
        navigate('/skillTestInstruction')
    }


    const handleJavaDev = () => {
        const targetUrl = `/testOptions?language=java`;
        dispatch(setSelectedSkill('java'))
        if (currentUser) {
            navigate('/testOptions?language=java')
        } else {
            navigate(`/signin?redirect=${targetUrl}`);
        }
    }

    const handleDataAnalyst = () => {
        const targetUrl = `/testOptions?language=dataanalyst`;
        dispatch(setSelectedSkill('dataanalyst'))
        if (currentUser) {
            navigate('/testOptions?language=dataanalyst')
        } else {
            navigate(`/signin?redirect=${targetUrl}`);
        }
    }

    const handleBusinessAnalyst = () => {
        const targetUrl = `/testOptions?language=businessAnalyst`;
        dispatch(setSelectedSkill('businessAnalyst'))
        if (currentUser) {
            navigate('/testOptions?language=businessAnalyst')
        } else {
            navigate(`/signin?redirect=${targetUrl}`);
        }
    }

    const handleBusinessIntelligence = () => {
        const targetUrl = `/testOptions?language=businessIntelligence`;
        dispatch(setSelectedSkill('businessIntelligence'))
        if (currentUser) {
            navigate('/testOptions?language=businessIntelligence')
        } else {
            navigate(`/signin?redirect=${targetUrl}`);
        }
    }

    const handleFinancialAnalyst = () => {
        const targetUrl = `/testOptions?language=financialAnalyst`;
        dispatch(setSelectedSkill('financialAnalyst'))
        if (currentUser) {
            navigate('/testOptions?language=financialAnalyst')
        } else {
            navigate(`/signin?redirect=${targetUrl}`);
        }
    }

    //------------------ Handle Coming Soon --------------------
    const handleComingSoon = () => {
        if (currentUser) {
            navigate('/comingSoon')
        } else {
            navigate('/signin')
        }
    }
    return (
        <div className='w-screen flex justify-center'>

            <div className='flex justify-center flex-wrap items-center gap-10 mt-6 width-container'>
                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={java} ></Lottie> </div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">JAVA Developer</h5>
                    </h1>
                    <p className="mb-3 text-xs font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:  </b>Basic JAVA</p>
                    <Button onClick={handleJavaDev} color='blue'>Take the test<FaArrowRight className='ml-2 mt-1' /></Button>
                </div>

                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={dataAnalyst} ></Lottie> </div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Data Analyst</h5>
                    </h1>
                    <p className="mb-3 text-xs font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:  </b>Power BI, Python, Excel, SQL</p>
                    <Button onClick={handleDataAnalyst} color='blue'>Take the test<FaArrowRight className='ml-2 mt-1' /></Button>
                </div>

                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow  hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={businessAnalyst} ></Lottie> </div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Business Analyst</h5>
                    </h1>
                    <p className="mb-3 text-xs font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:  </b>Power BI, Tableau, Advance Excel, SQL, Statistical Analytics</p>
                    <Button onClick={handleBusinessAnalyst} color='blue'>Take the test<FaArrowRight className='ml-2 mt-1' /></Button>
                </div>


                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={businessIntelligence} ></Lottie> </div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Business Intelligence</h5>
                    </h1>
                    <p className="mb-3 text-xs font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:  </b>Power BI, Tableau, Advance Excel, SQL, Statistical Analytics</p>
                    <Button onClick={handleBusinessIntelligence} color='blue'>Take the test<FaArrowRight className='ml-2 mt-1' /></Button>
                </div>

                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={financialAnalyst} ></Lottie> </div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Financial Analyst</h5>
                    </h1>
                    <p className="mb-3 text-[10px] font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:  </b>Excel, Power BI, SQL, Business Studies, Quant, Fundamental and Technical Analysis, Stock Market, Economics</p>
                    <Button onClick={handleFinancialAnalyst} color='blue'>Take the test<FaArrowRight className='ml-2 mt-1' /></Button>
                </div>

                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={python}></Lottie></div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">PYTHON Developer</h5>
                    </h1>
                    <p className="mb-3 text-xs font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:</b>Python</p>
                    <Button onClick={handleComingSoon} color='blue'>Take the test<FaArrowRight className='ml-2 mt-1' /></Button>
                </div>

                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={c}></Lottie></div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">C++ Developer</h5>
                    </h1>
                    <p className="mb-3 text-xs font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:</b>C++</p>
                    <Button onClick={handleComingSoon} color='blue'>Take the test<FaArrowRight className='ml-2 mt-1' /></Button>
                </div>
            </div>
        </div>
    )
}
