import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = () => {
    const data = {
        labels: ['Red', 'Blue', 'Yellow'],
        datasets: [
            {
                label: '# of Votes',
                data: [12, 19, 3],
                backgroundColor: ['red', 'blue', 'yellow'],
                hoverOffset: 4,
            },
        ],
    };

    return (
        <div className='w-96 h-96'>
            <Pie data={data} />
        </div>
    );
};

export default PieChart;