import React, { useState, useCallback, useEffect } from "react";
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentUser } from "../Redux/user/userSlice";
import { ImCross } from 'react-icons/im';
import { current } from "@reduxjs/toolkit";
import { useNavbarVisibility } from "./NavbarVisibilityContext.jsx";
import { useFooterVisibility } from "./FooterVisibilityContext.jsx";




const majorSkillOptions = [
  { value: "Web Development", label: "Web Development" },
  { value: "Java Developer", label: "Java Developer" },
  { value: "Data Analyst", label: "Data Analyst" },
  { value: "Business Intelligence", label: "Business Intelligence" },
  { value: "Business Analyst", label: "Business Analyst" },
  { value: "Data Science", label: "Data Science" },
  { value: "Cloud Computing", label: "Cloud Computing" },
  { value: "DevOps Engineer", label: "DevOps Engineer" },
  { value: "Mobile App Development", label: "Mobile App Development" },
  { value: "Machine Learning", label: "Machine Learning" },
  { value: "AI/Deep Learning", label: "AI/Deep Learning" },
  { value: "Cybersecurity", label: "Cybersecurity" },
  { value: "UI/UX Design", label: "UI/UX Design" },
  { value: "Full Stack Developer", label: "Full Stack Developer" },
  { value: "Software Engineering", label: "Software Engineering" },
  { value: "Project Management", label: "Project Management" },
  { value: "Game Development", label: "Game Development" },
  { value: "Blockchain Developer", label: "Blockchain Developer" },
  { value: "AR/VR Development", label: "AR/VR Development" },
  { value: "Database Administrator", label: "Database Administrator" },
  { value: "Digital Marketing", label: "Digital Marketing" },
  { value: "Other", label: "Other" }
];

// Skill suggestions
const skillSuggestions = [
  {
    label: "Web Development",
    options: [
      { value: "HTML", label: "HTML" },
      { value: "CSS", label: "CSS" },
      { value: "JavaScript", label: "JavaScript" },
      { value: "React", label: "React" },
      { value: "Angular", label: "Angular" },
    ],
  },
  {
    label: "Data Science",
    options: [
      { value: "Python", label: "Python" },
      { value: "R", label: "R" },
      { value: "Machine Learning", label: "Machine Learning" },
      { value: "Deep Learning", label: "Deep Learning" },
      { value: "Pandas", label: "Pandas" },
    ],
  },
  {
    label: "Cloud Computing",
    options: [
      { value: "AWS", label: "AWS" },
      { value: "Azure", label: "Azure" },
      { value: "Google Cloud", label: "Google Cloud" },
      { value: "Docker", label: "Docker" },
      { value: "Kubernetes", label: "Kubernetes" },
    ],
  },
  {
    label: "Data Analyst",
    options: [
      { value: "SQL", label: "SQL" },
      { value: "Excel", label: "Excel" },
      { value: "Data Visualization", label: "Data Visualization" },
      { value: "ETL", label: "ETL" },
      { value: "Power Query", label: "Power Query" },
    ],
  },
  {
    label: "Business Analyst",
    options: [
      { value: "Requirements Gathering", label: "Requirements Gathering" },
      { value: "UML", label: "UML" },
      { value: "Process Mapping", label: "Process Mapping" },
      { value: "Gap Analysis", label: "Gap Analysis" },
      { value: "Stakeholder Management", label: "Stakeholder Management" },
    ],
  },
  {
    label: "Business Intelligence",
    options: [
      { value: "Power BI", label: "Power BI" },
      { value: "Tableau", label: "Tableau" },
      { value: "Data Warehousing", label: "Data Warehousing" },
      { value: "Reporting", label: "Reporting" },
      { value: "Dashboarding", label: "Dashboarding" },
    ],
  },
];


const visaStatusOptions = [
  { value: "H-1B", label: "H-1B" },
  { value: "F-1", label: "F-1" },
  { value: "J-1", label: "J-1" },
  { value: "L-1", label: "L-1" },
  { value: "Green Card", label: "Green Card" },
  { value: "Other", label: "Other" },
  { value: "US Citizen", label: "US Citizen" }
];

const ageOptions = ["18-24", "25-30", "31-35", "36-40", "40+"];

const UserForm = () => {
  const { currentUser } = useSelector((state) => state.user);

  const [formData, setFormData] = useState({
    name: currentUser.name,
    age: currentUser?.userInfo.age,
    bio: currentUser?.userInfo.bio || 'Passionate software developer specializing in interactive applications with React and Tailwind CSS. Skilled in creating dynamic components and real-time functionality, with a keen interest in emerging technologies like TensorFlow JS. Enjoys sharing insights on software development trends and mentoring aspiring developers to enhance their skills.',
    majorSKill: currentUser?.majorSKill,
    visaStatus: '',
    otherVisaStatus: '',
    location: currentUser?.userInfo.location,
    phoneNumber: currentUser?.userInfo.phoneNumber,
    countryCode: "",
    experience: [...currentUser?.userInfo.experience],
    skills: [...currentUser?.userInfo.skills],
    totalYearsOfExperience: currentUser?.userInfo.totalYearsOfExperience,
    collageName: currentUser?.userInfo.mostRecentDegree.collageName,
    degree: currentUser?.userInfo.mostRecentDegree.degreeName,
    startYear: currentUser?.userInfo.mostRecentDegree.startYear,
    endYear: currentUser?.userInfo.mostRecentDegree.endYear,
    relocate: currentUser?.userInfo.relocate,
    relocateLocations: [...currentUser?.userInfo.relocateLocations],
    courses: [...currentUser?.userInfo.courses],
    isWorking: currentUser?.userInfo.isWorking,
  });

  const { setIsNavbarVisible } = useNavbarVisibility();
  const { setIsFooterVisible } = useFooterVisibility();

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [newExperience, setNewExperience] = useState({ domain: "", yearsOfExperience: "" });
  const [otherSkill, setOtherSkill] = useState("");
  const [majorSkillTypedValue, setMajorSkillTypedValue] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();


  // remove Navbar and Footer 
  useEffect(() => {
    setIsFooterVisible(false);
    setIsNavbarVisible(false);
  }, [])


  // -------------------- fuction to save the from data -------------------------------
  const handleInputChange = (e) => {
    const { name, value, type, radio } = e.target;

    // Get the current phoneNumber value (ensure it's a string or empty if undefined)
    let phoneNumber = formData.phoneNumber || "";

    if (name === "countryCode") {
      // Update the country code part and keep the number part intact
      const numberPart = phoneNumber.split(' ').slice(1).join('') || "";
      setFormData({
        ...formData,
        phoneNumber: `${value} ${numberPart}`
      });
    } else if (name === "phoneNumber") {
      // Update the number part and keep the country code intact
      const countryCode = phoneNumber.split(' ')[0] || "+1"; // Default to +1 if no country code yet
      setFormData({
        ...formData,
        phoneNumber: `${countryCode} ${value}`
      });
    } else {
      // Handle radio buttons
      if (type === "radio") {
        setFormData({ ...formData, [name]: value === "true" });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    }
  };



  const handleMajorSkillChange = (selected) => {
    setFormData({ ...formData, majorSKill: selected.value });
  };


  const handleMajorSkillTyped = (event) => {
    setMajorSkillTypedValue(event.target.value)
  };


  const handleSkillChange = (selected) => {
    setSelectedSkills(selected);
    const skills = selected.map((skill) => skill.value);
    setFormData({ ...formData, skills });
  };


  const handleOtherSkillChange = (e) => {
    setOtherSkill(e.target.value);
  };

  const handleAddOtherSkill = () => {
    if (otherSkill.trim() && !formData.skills.includes(otherSkill.trim())) {
      const updatedSkills = [...formData.skills, otherSkill.trim()];
      setFormData({ ...formData, skills: updatedSkills });
      const newSkill = { value: otherSkill.trim(), label: otherSkill.trim() };
      setSelectedSkills([...selectedSkills, newSkill]);
      setOtherSkill("");
    }
  };

  const handleDeleteExperience = (index) => {
    // Create a new array without the experience at the given index
    const updatedExperience = formData.experience.filter((_, i) => i !== index);

    // Update the state with the new experience array
    setFormData((prev) => ({ ...prev, experience: [...updatedExperience] }))
  };


  const handleExperienceInputChange = (e) => {
    const { name, value } = e.target;
    setNewExperience({ ...newExperience, [name]: value });
  };

  const addExperience = () => {
    if (newExperience.domain && newExperience.yearsOfExperience) {
      setFormData({
        ...formData,
        experience: [...formData.experience, newExperience],
      });
      setNewExperience({ domain: "", yearsOfExperience: "" }); // Reset the input fields
    }
  };

  const handleVisaStatusChange = (selected) => {
    if (selected.value === 'Other') {
      setFormData({ ...formData, visaStatus: selected.value, otherVisaStatus: '' });
    } else {
      setFormData({ ...formData, visaStatus: selected.value, otherVisaStatus: null });
    }
  };

  const handleOtherVisaStatusChange = (event) => {
    setFormData({ ...formData, otherVisaStatus: event.target.value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Restructuring formData to match the Mongoose model schema
    const requestBody = {
      name: formData.name,
      majorSKill: majorSkillTypedValue === '' ? formData.majorSKill : majorSkillTypedValue,
      profilePicture: formData.profilePicture,
      location: formData.location,
      isRecruiter: currentUser.isRecruiter, // If needed to be updated
      isAdmin: currentUser.isAdmin,         // If needed to be updated
      userInfo: {
        bio: formData.bio,
        age: formData.age,
        phoneNumber: formData.phoneNumber,
        location: formData.location,
        skills: formData.skills,
        visaStatus: formData.visaStatus === 'Other' ? formData.otherVisaStatus : formData.visaStatus,
        mostRecentDegree: {
          collageName: formData.collageName,
          degreeName: formData.degree,
          startYear: formData.startYear,
          endYear: formData.endYear,
        },
        relocate: formData.relocate,
        relocateLocations: formData.relocateLocations,
        experience: formData.experience,
        totalYearsOfExperience: formData.totalYearsOfExperience,
        isWorking: formData.isWorking,
        courses: formData.courses,
      }
    };

    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/updateInfo?userId=${currentUser._id}`, {
        method: "PUT",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody)
      });

      if (res.ok) {
        const data = await res.json();
        dispatch(updateCurrentUser(data.user));
        setTimeout(() => {

          setIsFooterVisible(true);
          setIsNavbarVisible(true);
          
          navigate('/dashboard?tab=profile'); // Redirect after animation
        }, 300); // Match the animation duration
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg space-y-6"
    >
      {/* Professional Info */}

      <h3 className="text-2xl font-semibold text-center text-gray-700">Professional Information</h3>
      <div>
        <label className="block text-base text-gray-700 font-semibold">Name</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          className="mt-1 p-2 border border-gray-300 rounded-md w-full text-sm"
        />
      </div>

      <div>
        <label className="block text-base text-gray-700 font-semibold">Age</label>
        <select
          name="age"
          value={formData.age}
          onChange={handleInputChange}
          className="mt-1 p-2 border border-gray-300 rounded-md w-full text-sm"
        >
          {ageOptions.map((age) => (
            <option key={age} value={age}>
              {age}
            </option>
          ))}
        </select>
      </div>

      <div>

        <label className="block text-base text-gray-700 font-semibold">About you</label>
        <textarea
          name="bio"
          value={formData.bio}
          onChange={handleInputChange}
          placeholder="Write a short bio..."
          className="mt-1 p-2 border border-gray-300 rounded-md w-full text-sm"
          rows="6"
        />
      </div>


      <div>
        <label className="block text-base text-gray-700 font-semibold">Phone Number</label>
        <div className="mt-1 flex">
          <select
            name="countryCode"
            value={formData.phoneNumber?.split(' ')[0] || "+1"} // Extract the country code, default to +1
            onChange={handleInputChange}
            className="p-2 border border-gray-300 rounded-l-md text-sm"
          >
            <option value="+1">+1 (USA)</option>
            <option value="+44">+44 (UK)</option>
            <option value="+91">+91 (India)</option>
            <option value="+61">+61 (Australia)</option>
            {/* Add more country codes as needed */}
          </select>
          <input
            type="tel"
            name="phoneNumber"
            value={formData.phoneNumber?.split(' ').slice(1).join('') || ""} // Extract the phone number part
            onChange={handleInputChange}
            placeholder="Phone Number"
            className="p-2 border border-gray-300 rounded-r-md w-full text-sm"
          />
        </div>
      </div>


      <div className="mt-4">
        <label className="block text-base text-gray-700 font-semibold">
          Total Number of Experience (in years)
        </label>
        <p className="text-[12px] text-gray-500">Select the total years of experience from the options</p>
        <select
          name="totalYearsOfExperience"
          value={formData.totalYearsOfExperience}
          onChange={handleInputChange}
          className="mt-1 p-2 border border-gray-300 rounded-md w-full text-sm"
        >
          <option value="Fresher 0">Less than 1 year(Fresher)</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="8+">8+</option>
        </select>
      </div>



      <div>
        <label className="block text-base text-gray-700 font-semibold">Current Location</label>
        <p className="text-[12px] text-gray-500">Write your current location you currently living in. e.g.-(New York)</p>
        <input
          type="text"
          name="location"
          value={formData.location}
          onChange={handleInputChange}
          className="mt-1 p-2 border border-gray-300 rounded-md w-full text-sm"
        />
      </div>

      {/* <div>
        <label className="block text-sm font-medium text-gray-700">
          Major Skill
        </label>
        <input
          type="text"
          name="majorSkill"
          value={formData.majorSkill}
          onChange={handleInputChange}
          className="mt-1 p-2 border border-gray-300 rounded-md w-full"
        />
      </div> */}
      <div>
        <label className="block text-base text-gray-700 font-semibold">Major Skill</label>
        <p className="text-[12px] text-gray-500">Select your specific field of work. e.g.-(Data Analyst)</p>
        <Select
          options={majorSkillOptions}
          onChange={handleMajorSkillChange}
          defaultValue={formData.majorSKill}
          className="mt-1"
        />
        {formData.majorSKill === 'Other' && (
          <div className="mt-4">
            <label className="block text-sm text-gray-700 font-semibold">
              If Other Please specify your Major-Skill
            </label>
            <p className="text-[12px] text-gray-500">Please write your Major-SKill here.</p>
            <textarea
              className="mt-1 p-2 w-full border border-gray-300 rounded-md"
              onChange={handleMajorSkillTyped}
              placeholder="Enter your major skill"
            />
          </div>
        )}
      </div>

      {/* Other Fields */}


      <div>
        <label className="block text-base text-gray-700 font-semibold">
          Visa Status
        </label>
        <p className="text-[12px] text-gray-500">Please select your VISA type or US Citizen. e.g.-(H-1B or F-1)</p>
        <Select
          options={visaStatusOptions}
          onChange={handleVisaStatusChange}
          defaultValue={visaStatusOptions[visaStatusOptions.length - 1]}
          className="mt-1"
        />
        {formData.visaStatus === 'Other' && (
          <div className="mt-4">
            <label className="block text-sm text-gray-700 font-semibold">
              If Other Please specify your visa status
            </label>
            <p className="text-[12px] text-gray-500">Please write your VISA type here.</p>
            <textarea
              className="mt-1 p-2 w-full border border-gray-300 rounded-md"
              value={formData.otherVisaStatus}
              onChange={handleOtherVisaStatusChange}
              placeholder="Enter your visa status"
            />
          </div>
        )}
      </div>

      {/* <div>
        <label className="block text-sm font-medium text-gray-700">Skills</label>
        <Select
  isMulti
  name="skills"
  options={skillSuggestions}
  onChange={handleSkillChange}
  className="mt-1"
  filterOption={(option, searchInput) => {
    return (
      option.label.toLowerCase().includes(searchInput.toLowerCase()) ||
      option.value.toLowerCase().includes(searchInput.toLowerCase())
    );
  }}
  placeholder="Search or select skills..."
/>
      </div> */}
      <div>
        <label className="block text-base text-gray-700 font-semibold">Skills</label>
        <p className="text-[12px] text-gray-500">Please add yours skills one by one from the dropdown or by typing it. If not present in the list add it from the below section.</p>
        <Select
          isMulti
          name="skills"
          options={skillSuggestions}  // Flatten the skillSuggestions
          value={selectedSkills}  // Set the selected skills to be displayed
          onChange={handleSkillChange}
          className="mt-1 text-sm"
          filterOption={(option, searchInput) => {
            return (
              option.label.toLowerCase().includes(searchInput.toLowerCase()) ||
              option.value.toLowerCase().includes(searchInput.toLowerCase())
            );
          }}
          placeholder="Search or select skills..."
        />
      </div>

      {/* Add custom skill */}
      <div className="mt-2">
        <label className="block text-base text-gray-700 font-semibold">Other Skill</label>
        <p className="text-[12px] text-gray-500">Please enter unlisted skill here and add it.</p>
        <div className="flex items-center space-x-2">
          <input
            type="text"
            name="otherSkill"
            value={otherSkill}
            onChange={handleOtherSkillChange}
            placeholder="Add another skill"
            className="mt-1 p-2 border border-gray-300 rounded-md w-full text-sm"
          />
          <button
            type="button"
            onClick={handleAddOtherSkill}
            className="text-white bg-blue-500 hover:bg-blue-600 p-2 rounded text-sm"
          >
            Add
          </button>
        </div>
      </div>

      <div>
        <h3 className="text-2xl font-semibold text-gray-700">Most recent Qualification</h3>
        <p className="text-[12px] text-gray-500">Last qualification or degree from any University or Institute.</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-base text-gray-700 font-semibold">College Name</label>
          <p className="text-[12px] text-gray-500">Write University or Institute name.</p>
          <input
            type="text"
            name="collageName"
            value={formData.collageName}
            onChange={handleInputChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
          />
        </div>
        <div>
          <label className="block text-base text-gray-700 font-semibold">Degree</label>
          <p className="text-[12px] text-gray-500">Write down the degree name.</p>
          <input
            type="text"
            name="degree"
            value={formData.degree}
            onChange={handleInputChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-base text-gray-700 font-semibold">Start Year</label>
          <p className="text-[12px] text-gray-500">Enter the start year of the Degree.</p>
          <input
            type="date"
            name="startYear"
            value={formData.startYear}
            onChange={handleInputChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
          />
        </div>
        <div>
          <label className="block text-base text-gray-700 font-semibold">End Year</label>
          <p className="text-[12px] text-gray-500">Enter the end year of the Degree.</p>
          <input
            type="date"
            name="endYear"
            value={formData.endYear}
            onChange={handleInputChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
          />
        </div>
      </div>

      {/* Experience */}
      <div>
        <h3 className="text-2xl font-semibold text-gray-700">Experiences</h3>
        <p className="text-[12px] text-gray-500">Enter your experience, with post name, company name and the time you served(year).</p>
        <p className="text-[12px] text-gray-500"> Then click the "Add Experience" button to save the record.</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-base text-gray-700 font-semibold">Domain</label>
          <p className="text-[12px] text-gray-500"> Example-(Data Analyst, JP MorganChase)</p>
          <input
            type="text"
            name="domain"
            value={newExperience.domain}
            onChange={handleExperienceInputChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            placeholder="e.g. Web Development, JP MorganChase"
          />
        </div>
        <div>
          <label className="block text-base text-gray-700 font-semibold">Years of Experience</label>
          <p className="text-[12px] text-gray-500">Enter served years. Example - 3(years)</p>
          <input
            type="number"
            name="yearsOfExperience"
            value={newExperience.yearsOfExperience}
            onChange={handleExperienceInputChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            placeholder="e.g. 3"
          />
        </div>
      </div>

      <button
        type="button"
        onClick={addExperience}
        className="mt-4 px-4 py-2 bg-teal-500 text-white font-semibold rounded-md shadow hover:bg-teal-700"
      >
        Add Experience
      </button>

      {/* Experience List */}
      {formData.experience.length > 0 && (
        <div className="mt-4">
          <h4 className="text-xl font-semibold text-gray-700">Your Experience</h4>
          <ul className="mt-2 space-y-2">
            {formData.experience.map((exp, index) => (
              <li key={index} className="p-3 border border-gray-300 rounded-md bg-gray-50">
                <span className="flex flex-row justify-between">
                  <span><strong>{exp.domain}</strong>: {exp.yearsOfExperience} years</span>

                  <ImCross className="inline-block  mx-3 cursor-pointer" color="grey" size={20} onClick={() => handleDeleteExperience(index)} />
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Working Status */}
      <div>
        <h3 className="text-2xl font-semibold text-gray-700">Current Status</h3>
        <p className="text-[12px] text-gray-500">Ensure currently you're working or not.</p>
      </div>
      <div className="flex gap-5">
        <label className="block text-base text-gray-700 font-semibold">
          Are you currently working?
        </label>
        <div className="flex items-center gap-2">
          <label>
            <input
              type="radio"
              name="isWorking"
              value={true}
              checked={formData.isWorking === true}
              onChange={handleInputChange}
              className="mr-2"
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name="isWorking"
              value={false}
              checked={formData.isWorking === false}
              onChange={handleInputChange}
              className="mr-2"
            />
            No
          </label>
        </div>
      </div>


      {/* Relocation */}
      <div>
        <h3 className="text-2xl font-semibold text-gray-700">Relocation</h3>
        <p className="text-[12px] text-gray-500">Are you willing to relocate? Ensure by YES or NO.</p>
      </div>
      <div className="flex gap-5">
        <label className="block text-base text-gray-700 font-semibold">Are you willing to relocate?</label>
        <div className="flex gap-3">
          <label>
            <input
              type="radio"
              name="relocate"
              value="true"
              checked={formData.relocate === true}
              onChange={handleInputChange}
            /> Yes
          </label>
          <label>
            <input
              type="radio"
              name="relocate"
              value="false"
              checked={formData.relocate === false}
              onChange={handleInputChange}
            /> No
          </label>
        </div>
      </div>

      {/* Desired Relocation Locations */}
      {formData.relocate && (
        <div>
          <label className="block text-base text-gray-700 font-semibold">
            Add Desired Relocations
          </label>
          <p className="text-[12px] text-gray-500">Enter your desired relocate locations followed by a comma(,). Example - (New York, Chicago)</p>
          <input
            type="text"
            name="relocateLocations"
            value={formData.relocateLocations || ''}
            onChange={(e) =>
              setFormData({
                ...formData,
                relocateLocations: e.target.value.split(","),
              })
            }
            placeholder="Enter locations separated by commas"
            className="mt-1 p-2 border border-gray-300 rounded-md w-full text-sm"
          />
        </div>
      )}


      {/* Courses */}
      <h3 className="text-2xl font-semibold text-gray-700">Courses</h3>
      <div>
        <label className="block text-base text-gray-700 font-semibold">Courses</label>
        <p className="text-[12px] text-gray-500">Enter courses done by you followed by a comma(,). Example - (Power BI, Tableau, AWS Certification)</p>
        <input
          type="text"
          name="courses"
          value={formData.courses}
          onChange={(e) =>
            setFormData({
              ...formData,
              courses: e.target.value.split(","),
            })
          }
          placeholder="Add courses separated by commas"
          className="mt-1 p-2 border border-gray-300 rounded-md w-full text-sm"
        />
      </div>

      {/* Submit Button */}
      <button
        onClick={handleSubmit}
        type="submit"
        className="mt-4 px-4 py-2 bg-blue-500 text-white font-semibold rounded-md shadow hover:bg-blue-600"
      >
        Submit
      </button>
    </form>
  );
};

export default UserForm;
