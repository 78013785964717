import { Footer } from "flowbite-react";
import { Link } from "react-router-dom";
import { BsFacebook, BsInstagram, BsLinkedin, } from 'react-icons/bs';
import { IoCall, IoLocationSharp, IoMail, IoTimerOutline } from 'react-icons/io5'
import { FaXTwitter } from 'react-icons/fa6'
import { useFooterVisibility } from "./FooterVisibilityContext.jsx";
export default function FooterCom() {
    const { isFooterVisible } = useFooterVisibility();
    if (isFooterVisible === false) {
        return null;
    }
    return (
        <Footer className="border border-t w-screen mt-14">
            <div className="w-full max-w-7xl mx-auto width-container">
                <div className="grid w-full justify-between sm:flex md:grid-cols-1">
                    <div className="mt-5">
                        <a href="/">
                            <img src="./images/logo-blue.png"  className="w-28" alt="horg logo" />
                        </a>
                    </div>
                    <div className="grid grid-cols-2 gap-8 mt-4 sm:grid-cols-3 sm:gap-6">
                        <div>
                            <Footer.Title title="ABOUT" />
                            <Footer.LinkGroup col>
                                <Footer.Link
                                    href="/about">
                                    About Us
                                </Footer.Link>
                            </Footer.LinkGroup>
                        </div>
                        {/* <div>
                            <Footer.Title title="FOLLOW US" />
                            <Footer.LinkGroup col>
                                <Footer.Link
                                    href="https://github.com/SudipGhara19"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    Github
                                </Footer.Link>
                                <Footer.Link
                                    href="https://linkedin.com/in/sudip-ghara-b24865214"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    Linkedin
                                </Footer.Link>
                            </Footer.LinkGroup>
                        </div> */}
                        <div>
                            <Footer.Title title="LEGAL" />
                            <Footer.LinkGroup col>
                                <Footer.Link
                                    href="https://bendainfotech.com/privacy-policy/">
                                    Privacy Policy
                                </Footer.Link>
                                <Footer.Link
                                    className="text-gray-700"
                                    href="https://bendainfotech.com/terms-of-use/">
                                    Terms & Conditions
                                </Footer.Link>
                            </Footer.LinkGroup>
                        </div>
                        <div>
                            <Footer.Title title="CONTACT US" />
                            <Footer.LinkGroup col>
                                <Footer.Link
                                >
                                    <div className="flex flex-row gap-2">
                                        <IoCall />
                                        <p className="cursor-pointer">+1 224-552-0195</p>
                                    </div>
                                </Footer.Link>
                                {/* <Footer.Link
                                    >
                                    <div className="flex flex-row gap-2">
                                        <IoCall />
                                        <p className="cursor-pointer">+1 219-224-2403</p>
                                    </div>
                                </Footer.Link> */}
                                {/* <Footer.Link
                                    >
                                    <div className="flex flex-row gap-2">
                                        <IoTimerOutline />
                                        <p className="cursor-pointer">09:00-18:00 | Mon-Fri</p>
                                    </div>
                                </Footer.Link> */}
                                <Footer.Link
                                >
                                    <div className="flex flex-row gap-2">
                                        <IoLocationSharp />
                                        <p className="cursor-pointer">Chicago, Illinois</p>
                                    </div>
                                </Footer.Link>
                                <Footer.Link
                                    className="text-gray-800"
                                    target="_blank"
                                    rel="noopener noreferrer" href="mailto:info@bendainfotech.com"
                                >
                                    <div className="flex flex-row gap-2">
                                        <IoMail />
                                        <p className="cursor-pointer">info@bendainfotech.com</p>
                                    </div>
                                </Footer.Link>
                            </Footer.LinkGroup>
                        </div>
                    </div>
                </div>
                <Footer.Divider />
                <div className="w-full sm:flex sm:items-center sm:justify-between mb-4">
                    <Footer.Copyright href="#" by="Benda Infotech LLC. All rights reserved." year={new Date().getFullYear()} />
                    <div className="flex gap-6 sm:mt-0 mt-4 sm:justify-center">
                        <Footer.Icon target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.facebook.com/bendainfotech"
                            icon={BsFacebook} />
                        <Footer.Icon target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.instagram.com/benda_infotech/"
                            icon={BsInstagram} />
                        <Footer.Icon target="_blank"
                            rel="noopener noreferrer"
                            href="https://x.com/BendaInfotech"
                            icon={FaXTwitter} />
                        <Footer.Icon target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.linkedin.com/company/benda-infotech/"
                            icon={BsLinkedin} />
                    </div>
                </div>
            </div>
        </Footer>
    )
}