import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    selectedSkill: ''
};


const selectSkillSlice = createSlice({
    name: 'selectSkill',
    initialState,
    reducers: {
        setSelectedSkill: (state, action) => {
            state.selectedSkill = action.payload
        },
        removeSelectedSkill: (state) => {
            state.selectedSkill = '';
        }

    }
})


export const {setSelectedSkill, removeSelectedSkill} = selectSkillSlice.actions;
export const selectSkillReducer = selectSkillSlice.reducer;