import { useDispatch, useSelector } from "react-redux";
import { signInStart, signInSuccess, signInFailure } from "../../Redux/user/userSlice.js";
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate, useLocation } from "react-router-dom";
import { updateAllViewedProfiles, updateRecruiterInfo } from "../../Redux/recruiterSlice.js";
import { app } from "../../firebase.js";
import { Button, Spinner } from "flowbite-react";

// Firebase Auth
const auth = getAuth(app);

// Google Sign Up Component
export default function GoogleSignUp ({formData}) {

    const {loading} = useSelector((state) => state.user)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    // Function to handle Google sign-up
    const handleGoogleSignUpClick = async () => {
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({ prompt: 'select_account' });
        try {
            dispatch(signInStart());
            const resultFromGoogle = await signInWithPopup(auth, provider);
            const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/googleSignup`, {
                method: "POST",
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    name: resultFromGoogle.user.displayName,
                    email: resultFromGoogle.user.email,
                    googlePhotoUrl: resultFromGoogle.user.photoURL,
                    majorSKill: formData.majorSKill,
                    isRecruiter: formData.isRecruiter
                }),
            });

            const data = await res.json();

            if(res.status === 409){
                return dispatch(signInFailure("User already exists with this email! Try to Sign-In."));
            }

            if (res.ok) {
                dispatch(signInSuccess(data));
                // Check if the user needs to select a skill
                if (!data.isAdmin && !data.isRecruiter && data.majorSKill === '') {
                    navigate('/', {
                        state: { from: location.state?.from || location },
                        replace: true,
                    });
                } else {
                    // Update Redux state and redirect
                    dispatch(signInSuccess(data));

                    if (data.isRecruiter && data.recruiterInfoId !== '') {
                        try {
                            const recruiterRes = await fetch(`${process.env.REACT_APP_BACKEND}/api/recruiter/getRecruiter/${data.recruiterInfoId}`, {
                                method: 'GET',
                                headers: { 'Content-Type': 'application/json' },
                                credentials: 'include',
                            });

                            const recruiterInfo = await recruiterRes.json();
                            if (recruiterRes.ok) {
                                dispatch(updateRecruiterInfo(recruiterInfo.user));
                                dispatch(updateAllViewedProfiles(recruiterInfo.viewedProfiles));
                            }else{
                                dispatch(updateRecruiterInfo(recruiterInfo.user));
                            }
                        } catch (error) {
                            console.log(error);
                        }
                    }

                    const queryString = location.search;
                    const redirectPath = location.state?.from?.pathname || '/';
                    const finalRedirect = `${redirectPath}${queryString}`;
                    navigate(finalRedirect, { replace: true });
                }
            }
        } catch (error) {
            dispatch(signInFailure(error.message));
            console.log(error);
        }
    };

    // Return the component UI (you can customize this as needed)
    return (
        <>
            <Button
                className="bg-sky-500"
                onClick={handleGoogleSignUpClick}
                disabled={!formData.isRecruiter || formData.majorSKill === ''}
            >
                {loading ? (
                                <>
                                    <Spinner size='sm' />
                                    <p className='mr-2 text-sm'>Loading...</p>
                                </>
                            ) : 'Proceed'}
            </Button>
        </>
    );
};
